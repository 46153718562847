import React, { useState, useEffect, Suspense, lazy } from "react";
import HomeContact from "../home/homeContact";
import { Link } from "react-router-dom";
import flag1 from '../../assets/images/country/flag1.gif';
import flag2 from '../../assets/images/country/flag2.gif';
import flag3 from '../../assets/images/country/flag3.gif';
import flag4 from '../../assets/images/country/flag4.gif';
import flag5 from '../../assets/images/country/flag5.gif';
import flag6 from '../../assets/images/country/flag6.gif';
import flag7 from '../../assets/images/country/flag7.gif';
import flag8 from '../../assets/images/country/flag8.gif';
import axios from "axios";
import { baseURL,mediaUrl } from '../axiosInterceptor/axiosInterceptorC';
import emptyImg from '../../assets/images/emptyImg.jpg'
import emptyImg2 from '../../assets/images/emtyImg2.jpg'
import { IoCallOutline } from "react-icons/io5";

const CountriesBox = lazy(() => import("./countriesBox"));

const countryFlags = [flag1, flag2, flag3, flag4, flag5, flag6, flag7, flag8]; // Add more if necessary
const mediaPath = mediaUrl;

function Immigration() {
    const [countries, setCountries] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        async function fetchCountries() {
            try {
                const response = await axios.get(`${baseURL}/countries`);  // Adjust the API endpoint as necessary
                console.log(response.data.data)
                setCountries(response.data.data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        }

        fetchCountries();
    }, []);

    if (loading) {
        return <>
        <section id="countriesBanner">
                <div className="container">
                    <div className="countriesBannerMain">
                        <h1>Discover Global <br />Opportunities Worldwide</h1>
                    </div>
                </div>
            </section>

            <section id="countriesList">
                <div className="container">
                    <div className="countriesListMain">
                        <Suspense  key={1}>
                            <CountriesBox 
                                countryLink={``} 
                                countryFlag={mediaPath + 'hi'} 
                                countryTitle={""} 
                                countryJobs={ "N/A"} // Assuming the jobs count comes from the API
                            />
                        </Suspense>
                        <Suspense  key={2}>
                            <CountriesBox 
                                countryLink={``} 
                                countryFlag={mediaPath + 'hi'} 
                                countryTitle={""} 
                                countryJobs={ "N/A"} // Assuming the jobs count comes from the API
                            />
                        </Suspense>
                        
                    </div>
                </div>
            </section>
            </>;
    }

    if (error) {
        return <div>Error loading countries: {error}</div>;
    }

    return (
        <>
            <section id="countriesBanner">
                <div className="container">
                    <div className="countriesBannerMain">
                        <h1>Discover Global <br />Opportunities Worldwide</h1>
                    </div>
                </div>
            </section>

            <section id="countriesList">
                <div className="container">
                    <div className="countriesListMain">
                        {countries.length > 0 ? (
                            countries.map((country, index) => (
                                <Suspense  key={country.id}>
                                    <CountriesBox 
                                        countryLink={`/immigrationDetails/${country.id}`} 
                                        countryFlag={mediaPath + country.flag_image} 
                                        countryTitle={country.country_name} 
                                        countryJobs={country.job_count ||0} // Assuming the jobs count comes from the API
                                    />
                                </Suspense>
                            ))
                        ) : (
                            <div className="noDataFound">
                                <div className="noDataFoundIcon">
                                <img src={emptyImg2} alt="" />
                                </div>
                                <div className="noDataFoundDetails">
                                <h3>Opps!</h3>
                                <p>Immigration Not Available</p>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </section>

            <HomeContact />
        </>
    );
}

export default Immigration;
