import React from "react";
import { Link, Element, scroller } from 'react-scroll';
import HomeContact from "../home/homeContact";

function CancellationRefundPolicy() {

  const scrollToSection = (id) => {
    scroller.scrollTo(id, {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart',
      offset: -90 // Adjust this value based on your header height
    });
  };
  
  return (
    <>
        <section id="faqSection">
            <div className="container">
                <div className="faqSectionMain">
                  <div className="faqSectionHead">
                      <h1 className="mainTitle">Cancellation and Refund</h1>
                    </div>
                    <div className="faqSectionCol1">
                      <ul>
                        <li>
                          <Link 
                            to="cancellations"
                            className="faqLink"
                            activeClass="faqLinkActive"
                            spy={true}
                            smooth={true}
                            offset={-90}
                            duration={500}
                            onClick={() => scrollToSection('cancellations')}
                            >Cancellation and Refund</Link>
                        </li>
                        <li>
                          <Link
                            to="Refunds"
                            className="faqLink"
                            activeClass="faqLinkActive"
                            spy={true}
                            smooth={true}
                            offset={-90}
                            duration={500}
                            onClick={() => scrollToSection('shipping')}>Shipping and Delivery</Link>
                        </li>
                      </ul>
                    </div>
                    <div className="faqSectionCol2">
                      <div className="faqSectionCol2List">
                        <Element name="cancellations" className="faqSectionCol2ListBox">
                          <h2 className="title">Cancellation and Refund</h2>
                          <p>We are committed to providing quality services to our clients and candidates. However, once a recruitment process is initiated, we do not entertain cancellations or refunds. This policy ensures that our team can dedicate the necessary time and resources to finding the best talent or job match for our clients and candidates. We recommend thoroughly reviewing our services and agreements before proceeding. Should you have any questions or require clarification, please contact our team, and we will be happy to assist.</p>
                        </Element>
                        <Element name="shipping" className="faqSectionCol2ListBox">
                          <h2 className="title">Shipping and Delivery</h2>
                          <p>As we provide recruitment services, there are no physical products involved, and thus, shipping does not apply to our business. All services are delivered either digitally or through direct communication with our clients and candidates. Our team ensures that the recruitment process is conducted smoothly and efficiently, keeping all parties informed throughout the journey.</p>
                        </Element>
                      </div>
                    </div>
                </div>
            </div>
        </section>

        <HomeContact />
    </>
  );
}

export default CancellationRefundPolicy;
