import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import HomeContact from "../home/homeContact";
import BackBtn from "../settings/backBtn";
import api, { baseURL ,mediaUrl} from '../axiosInterceptor/axiosInterceptorC';

// Lazy load images and components if needed
// const path = "/danielsjobfinder/demo1";

function InnerBlog() {
    const { id } = useParams(); // Extract blog ID from URL params
    const [blog, setBlog] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
     

    useEffect(() => {
        const fetchBlog = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${baseURL}/blogonid/${id}`);
                setBlog(response.data);
            } catch (error) {
                console.error("Error fetching blog details:", error);
                setError("Failed to fetch blog details");
            } finally {
                setLoading(false);
            }
        };

        fetchBlog();
    }, [id]);

    if (loading) return <>
    <section id="innerBlog">
        <div className="container">
            <BackBtn />
            <div className="innerBlogMain">
                <div className="innerBlogThumbnail loaded">
                    {/* Use the blog image from the fetched data */}
                    <img />
                </div>
                <div className="innerBlogDetails">
                    <div className="date">Posted on: </div>
                    <h1 className="mainTitle"></h1>
                    <div />
                </div>
            </div>
        </div>
    </section>

    <HomeContact />
</>;
    if (error) return <div>{error}</div>;

    return (
        <>
            <section id="innerBlog">
                <div className="container">
                    <BackBtn />
                    <div className="innerBlogMain">
                        <div className="innerBlogThumbnail">
                            {/* Use the blog image from the fetched data */}
                            <img src={mediaUrl + blog.blog_image} alt={blog?.blog_title || "Blog Image"} />
                        </div>
                        <div className="innerBlogDetails">
                            <div className="date">Posted on: {blog.added_date}</div>
                            <h1 className="mainTitle">{blog?.blog_title || "Blog Title"}</h1>
                            <div dangerouslySetInnerHTML={{ __html: blog?.blog_inner_description || "Blog content" }} />
                        </div>
                    </div>
                </div>
            </section>

            <HomeContact />
        </>
    );
}

export default InnerBlog;
