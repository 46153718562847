import React, { useState, useEffect } from "react";
import { FiSearch } from "react-icons/fi";
import Select from "react-select";
import { Link } from "react-router-dom";

import bannerVideo1 from '../../assets/images/banner/video1.mp4';
import bannerVideo2 from '../../assets/images/banner/video2.mp4';
import bannerVideo3 from '../../assets/images/banner/video3.mp4'; // Add more videos here
import api, { baseURL } from '../axiosInterceptor/axiosInterceptorC';
import axios from 'axios';

const experiences = [
    { value: "0-1", label: "0-1 Yrs" },
  { value: "1-2", label: "1-2 Yrs" },
  { value: "2-3", label: "2-3 Yrs" },
  { value: "3-4", label: "3-4 Yrs" },
  { value: "4-5", label: "4-5 Yrs" },
  { value: "5-6", label: "5-6 Yrs" },
  { value: "6-7", label: "6-7 Yrs" },
  { value: "7-8", label: "7-8 Yrs" },
  { value: "8-9", label: "8-9 Yrs" },
  { value: "9-10", label: "9-10 Yrs" },
  { value: "10-11", label: "10-11 Yrs" },
  { value: "11-12", label: "11-12 Yrs" },
  { value: "12-13", label: "12-13 Yrs" },
  { value: "13-14", label: "13-14 Yrs" },
  { value: "14-15", label: "14-15 Yrs" },
  { value: "15-16", label: "15-16 Yrs" },
  { value: "16-17", label: "16-17 Yrs" },
  { value: "17-18", label: "17-18 Yrs" },
  { value: "18-19", label: "18-19 Yrs" },
  { value: "19-20", label: "19-20 Yrs" },
  { value: "20+", label: "20+ Yrs" }
    // Add more options here
];

const path = "";

function HomeBanner({ onSearch }) {
    const [isClearable] = useState(true);
    const [currentVideo, setCurrentVideo] = useState(0); // Track current video index
    const [fade, setFade] = useState(true); // Track the fade effect

    const videoSources = [bannerVideo1, bannerVideo2, bannerVideo3]; // Array of videos
    const slideInterval = 5000; // Auto-slide interval in milliseconds (5 seconds)

    const [jobTitles, setjobTitles] = useState([]);
    const [locations, setlocations] = useState([]);
    // Array of content that changes with the video
    const headlines = [
        "Launch Your Dream Career in Canada!",
        "Boost Your Career in Germany’s Thriving Economy!",
        "Shape Your Future with Top Jobs in the UK!",
    ];

    const descriptions = [
        "Canada’s booming industries are calling! Find your perfect role and start thriving in one of the world’s most dynamic professional landscapes.",
        "Join Germany’s flourishing industries and unlock endless growth potential. Your next role is just a click away!",
        "From finance to tech, the UK offers unmatched opportunities. Discover your next big career leap today!",
    ];

    // Array of dynamic links for each video
    const [applyLinks, setApplyLinks] = useState([]);

    useEffect(() => {
        async function fetchCountries() {
            try {
                const response = await axios.get(`${baseURL}/fetch_service_country`);
                const newApplyLinks = response.data.data.map((x) => '/immigrationDetails/' + x.id);
                setApplyLinks(newApplyLinks);  // Set the new applyLinks
            } catch (err) {
                console.error(err);
            }
        }
        fetchCountries();
    }, []);

    const customStyles = {
        menu: (provided) => ({
            ...provided,
            zIndex: 100,
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#d2e2ff' : state.isFocused ? '#eee' : 'white',
            color: state.isSelected ? '#000' : 'inherit',
        }),
        singleValue: (provided) => ({
            ...provided,
            color: '#000',
        }),
    };

    // Function to handle video change
    const handleNextVideo = () => {
        setFade(false); // Start fading out the video
        setTimeout(() => {
            setCurrentVideo((prev) => (prev + 1) % videoSources.length); // Change the video
            setFade(true); // Fade in the new video
        }, 200); // Duration of fade-out before changing video
    };

    // Auto-slide effect using useEffect hook
    useEffect(() => {
        const autoSlide = setInterval(handleNextVideo, slideInterval);

        // Cleanup the interval on unmount
        return () => clearInterval(autoSlide);
    }, []);


    //job title section
    useEffect(() => {
    axios.get(`${baseURL}/fetch_all_job_titles`) // Replace with your API URL
        .then(response => {
        console.log(response.data)
        const fromattedtags = response.data.map(jobtitle => ({
            value: jobtitle.job_title,
            label: jobtitle.job_title 
            }));
            setjobTitles(fromattedtags);
        })
        .catch(error => {
        console.error('Error fetching items:', error);
        });
    }, []); 

    //location section
    useEffect(() => {
    axios.get(`${baseURL}/fetch_all_location`) // Replace with your API URL
        .then(response => {
        console.log(response.data)
        const fromattedtags = response.data.map(location_v => ({
            value: location_v.location_val,
            label: location_v.location_val 
            }));
            setlocations(fromattedtags);
        })
        .catch(error => {
        console.error('Error fetching items:', error);
        });
    }, []);

const [selectedJobTitle, setSelectedJobTitle] = useState(null); 
const [selectedLocation, setSelectedLocation] = useState(null); 
const [selectedExperience, setSelectedExperience] = useState(null);

const searchBtnclick = (e) => {
    e.preventDefault();
    const filters = {
      jobTitle: selectedJobTitle,
      location: selectedLocation,
      experience: selectedExperience,
    };

    onSearch(filters);
  };

// const handleSelectChange = (name, selectedOption) => {
//     switch (name) {
//         case 'jobtitle':
//             setSelectedJobTitle(selectedOption.value || []);
//             break;
//         case 'location':
//             setSelectedLocation(selectedOption.value || []);
//             break;
//         case 'experience':
//             setSelectedExperience(selectedOption.value || []);
//             break;
//         default:
//             break;
//     }
// };

const handleSelectChange = (name, selectedOption) => {
    switch (name) {
        case 'jobtitle':
            setSelectedJobTitle(selectedOption ? selectedOption.value : null); // Handle null case
            break;
        case 'location':
            setSelectedLocation(selectedOption ? selectedOption.value : null); // Handle null case
            break;
        case 'experience':
            setSelectedExperience(selectedOption ? selectedOption.value : null); // Handle null case
            break;
        default:
            break;
    }
};

    return (
        <section id="homeBanner">
            <div className="homeBannerSlider">
                <div className={`homeBannerSliderBox ${fade ? 'fade-in' : 'fade-out'}`}>
                    <video 
                        key={currentVideo}
                        autoPlay 
                        loop 
                        muted 
                        playsInline 
                        data-wf-ignore="true" 
                        data-object-fit="cover" 
                        preload="auto" 
                        loading="lazy"
                    >
                        <source src={videoSources[currentVideo]} />
                    </video>
                </div>
            </div>
            <div className="container">
                <div className="homeBannerMain">
                    <h1>{headlines[currentVideo]}</h1>
                    <p>{descriptions[currentVideo]}</p>
                    <Link to={applyLinks[currentVideo]} className="bannerApplyBtn">Apply Now</Link> {/* Dynamic Link */}
                    <div className="bannerForm">
                        <form action="">
                            <div className="formGroupBox">
                                <div className="formGroup">
                                    <Select
                                        options={jobTitles}
                                        placeholder="Job Title"
                                        className="customSelect"
                                        isClearable={isClearable}
                                        styles={customStyles}
                                        onChange={(selected) => handleSelectChange('jobtitle', selected)}
                                    />
                                </div>
                                <div className="formGroup">
                                    <Select
                                        options={locations}
                                        placeholder="Location"
                                        isSearchable={true}
                                        className="customSelect"
                                        isClearable={isClearable}
                                        styles={customStyles}
                                        onChange={(selected) => handleSelectChange('location', selected)}
                                    />
                                </div>
                                <div className="formGroup">
                                    <Select
                                        options={experiences}
                                        placeholder="Experience"
                                        className="customSelect"
                                        isClearable={isClearable}
                                        styles={customStyles}
                                        onChange={(selected) => handleSelectChange('experience', selected)}
                                    />
                                </div>
                            </div>
                            <div className="formBtnArea">
                                <button className="formSbtBtn" onClick={searchBtnclick}>
                                    <FiSearch /> Search
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default HomeBanner;
