import React, { useState,useEffect } from "react";
import { Link,useNavigate,useParams } from 'react-router-dom';
import BackBtn from "../settings/backBtn";
import HomeContact from "../home/homeContact";
import UploadResume from './uploadResume';
import EducationForm from "./educationForm";
import PersonalDetailsForm from "./personalDetailsForm";
import ReviewForm from "./reviewForm";
import successImg from '../../assets/images/success.png'
import ProfileImage from "../profile/profileImage";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
//import Loader from '../loader/buttonLoader';
import Select from "react-select";
import axios from 'axios';
import api, { baseURL,mediaUrl } from '../axiosInterceptor/axiosInterceptorC';
import { PiCaretRightBold, PiCaretLeftBold } from "react-icons/pi";
import Loader from '../loader/buttonLoader';
import { toast } from 'react-toastify';
import { useGlobalState } from '../globalProvider/globalProvider';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

const path = "";
const mediaPath = mediaUrl
//const [errors, setErrors] = useState({});



function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <div>{children}</div>
          </Box>
        )}
      </div>
    );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const locations = [
    { value: "new-york", label: "New York" },
    { value: "san-francisco", label: "San Francisco" },
    { value: "los-angeles", label: "Los Angeles" },
    // Add more options here
];

const place = [
    { value: "new-york", label: "New York" },
    { value: "san-francisco", label: "San Francisco" },
    { value: "los-angeles", label: "Los Angeles" },
    // Add more options here
];

const year = [
    { value: "1year", label: "1 Year" },
    { value: "2years", label: "2 Years" },
    { value: "3years", label: "3 Years" },
    { value: "4years", label: "4 Years" },
    { value: "5years", label: "5 Years" },
    { value: "6years", label: "6 Years" },
    { value: "7years", label: "7 Years" },
    { value: "8years", label: "8 Years" },
    { value: "9years", label: "9 Years" },
    { value: "10years", label: "10 Years" },
    { value: "10Above", label: "10+ Years" },
    // Add more options here
];

const month = [
    { value: "1month", label: "1 Month" },
    { value: "2month", label: "2 Months" },
    { value: "3month", label: "3 Months" },
    { value: "4month", label: "4 Months" },
    { value: "5month", label: "5 Months" },
    { value: "6month", label: "6 Months" },
    { value: "7month", label: "7 Months" },
    { value: "8month", label: "8 Months" },
    { value: "9month", label: "9 Months" },
    { value: "10month", label: "10 Months" },
    { value: "11month", label: "11 Months" },
    // Add more options here
];

function ApplyJobForm() {
    const { id } = useParams();

    const [isClearable] = useState(true);
    const [value, setValue] = useState(0);
    const [workStatus, setWorkStatus] = useState('fresher');
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const shimmer = document.querySelector('.shimmer');
    const body = document.querySelector('body');
    const [isLoading, setIsLoading] = useState(false);
    const applyJobFormList = document.querySelector('.applyJobFormList');
    const [inputs, setInputs] = useState({});
    const[croppedimages,setCroppedimage]=useState('');
    const[inatialImage,setInatialImage] = useState('');
   const [reumenameval,setreumenameval]= useState('');
    const [message, setMessage] = useState('');
    const [isSuccess, setIsSuccess] = useState(false);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [showFiledAlert, setShowFiledAlert] = useState(false);
    const { globalState, setGlobalState } = useGlobalState();
    const [serviceImage, setServiceImage] = useState(null);
    const [isImageUploaded, setIsImageUploaded] = useState(false);
    const [pdfFile,setPdfFile] = useState(null);
    const [pdfError,setPdfError] = useState(null);
    const [intailFile,setIntailFile] = useState(null);
    const [validationErrors, setValidationErrors] = useState({});
   //
    const navigate = useNavigate();
    const [personalErrors, setPersonalErrors] = useState({});
    const isMobile = useMediaQuery('(max-width:1024px)');
    const [loginId,setLoginId] = useState(null);

    const [rows, setRows] = useState([{ id: null ,startDate: null, endDate: null, companyName: '', position: '' }]); // Always have one row initially
    const [completionDate, setCompletionDate] = useState(null);
    const [education,setEducation] = useState([]);
    const [educationError,setEducationError] = useState([]);
    useEffect(() => {
        const storedOtpCode = localStorage.getItem('otp_code');
        const storeloginid = localStorage.getItem('login_id');
        const storeemailval = localStorage.getItem('emailval');
        const stcandidate_name = localStorage.getItem('candidate_name');

        //console.log(storeloginid)
        setLoginId(storeloginid)
       
    }, []);
    //console.log(loginId)
    const handleImageUpload = (file) => {
        setPdfFile(file);
        // console.log(file)
        if (file) {
            setIsImageUploaded(true);
            setErrors(prev => ({ ...prev, serviceImage: '' }));
        } else {
            setIsImageUploaded(false);
       }
   };

    // console.log(workStatus)
    const handleChange = (event, newValue) => {
        setValue(newValue);
        window.scrollTo(0, 0); // Scrolls to the top of the page
    };

    const handleNextClick = () => {
    
        if (value < 4) {  // Assuming there are 5 tabs (0 to 4)
            setValue((prevValue) => prevValue + 1);
        } else {
            setShowSuccessPopup(true);
            shimmer.style.display = 'block';
            body.style.overflow = 'hidden';
            applyJobFormList.style.position = 'inherit'; // Show success popup on last tab
        }
        window.scrollTo(0, 0);
    };

    const closeSuccessPopup = () => {
        shimmer.style.display = 'none';
        body.style.overflow = 'inherit';
        applyJobFormList.style.position = 'sticky';
    };

    const handlePrevClick = () => {
        if (value > 0) {  // Ensures that the tab index doesn't go below 0
            setValue((prevValue) => prevValue - 1);
        }
        window.scrollTo(0, 0);
    };

    const handleWorkStatusChange = (event) => {
        setWorkStatus(event.target.id); // 'fresher' or 'experience'
        setFormData({
            ...formData,
            ['work_status']: event.target.id
        });
    };

    // Function to handle adding a new row
    const addRow = () => {
        setRows([...rows, { id:null ,startDate: null, endDate: null, companyName: '', position: '' }]);
    };

    // Function to handle removing a row
    const removeRow = async (index,expId) => {
        const newRows = rows.filter((row, i) => i !== index);
        if (newRows.length === 0) {
            // Ensure there's always at least one row
            setRows([{ id:null,startDate: null, endDate: null, companyName: '', position: '' }]);
        } else {
            setRows(newRows);
        }
        try{
            const response = await axios.get(`${baseURL}/deleteExperience/${expId}`);
            console.log(response)
            
        }catch(err){
            console.log(err)
        }
    };

    
    const updateRow = (index, field, value) => {
        console.log(value);
        const updatedRows = [...rows];
        updatedRows[index][field] = value; 
        setRows(updatedRows);
        setFormData(prevFormData => ({
            ...prevFormData,
            experienceArray: updatedRows,
        }));
        let errors = { ...validationErrors };
        if (field === 'startDate') {
            if (!value) {
                errors[`startDate_${index}`] = 'Start date is required';
            } else {
                delete errors[`startDate_${index}`];
            }
        
            // If endDate is present, validate that it's after startDate
            if (updatedRows[index].endDate && dayjs(updatedRows[index].endDate).isBefore(dayjs(value))) {
                errors[`endDate_${index}`] = 'End date cannot be before start date';
            } else {
                delete errors[`endDate_${index}`];
            }
        }
        
        // Validation for end date only if it's present
        if (field === 'endDate' && value) {
            if (updatedRows[index].startDate && dayjs(value).isBefore(dayjs(updatedRows[index].startDate))) {
                errors[`endDate_${index}`] = 'End date cannot be before start date';
            } else {
                delete errors[`endDate_${index}`];
            }
        }
        
        if (field === 'companyName' && !value.trim()) {
            errors[`companyName_${index}`] = 'Company name is required';
        } else if (field === 'companyName') {
            delete errors[`companyName_${index}`];
        }
        if (field === 'position' && !value.trim()) {
            errors[`position_${index}`] = 'Position is required';
        } else if (field === 'position') {
            delete errors[`position_${index}`];
        }
        
        setValidationErrors(errors);
    };
    
    

    const customStyles = {
        menu: (provided) => ({
            ...provided,
            zIndex: 100,
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#d2e2ff' : state.isFocused ? '#eee' : 'white',
            color: state.isSelected ? '#000' : 'inherit',
        }),
        singleValue: (provided) => ({
            ...provided,
            color: '#000',
        }),
    };


    const [formData, setFormData] = useState({
        firstname:'',
        lastname:'',
        phonenumber:'',
        emailid:'',
        totalexperince:'',
        numbermonth:'',
        counrtryselect:'',
        placeselect:'',
        profilesummary:'',
        experienceArray : [],
      });

      const [errors, setErrors] = useState({
        firstname:'',
        lastname:'',
        phonenumber:'',
        emailid:'',
        totalexperince:'',
        numbermonth:'',
        counrtryselect:'',
        placeselect:'',
        profilesummary:'',
    });

    const handleChangev1 = (e) => {
        const name = e.target.name;
        const value = e.target.value.trim();
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
        const error = validate(name, value);
       setErrors((errors) => ({ ...errors, [name]: error[name] }));
        };

        const validate = (name, value) => {
            let error = {};
            if (name === 'job_title' && !value) {
                error.job_title = "Job title is required";
            } 
            return error;
        };
        //console.log(formData)

        const validateAllFields = (fields) => {
            
            
            let errors = {};
            for (let key in fields) {
                const error = validate(key, fields[key]);
                if (error[key]) {
                    errors[key] = error[key];
                }
            }
            return errors;
        };

        const handleCroppedImage = (blob) => {
            // console.log("Cropped Image Blob:", blob);
            setCroppedimage(blob);
            setGlobalState(blob);
            // Handle the blob value here, e.g., set it in state or send it to the backend
          };


          const handletotalexperiance = (selectedOption) => {
            setFormData({
              ...formData,
              totalexperince: selectedOption ? selectedOption.value : '' ,
            });
          };

          const handlenumbermonth = (selectedOption) => {
            setFormData({
              ...formData,
              numbermonth: selectedOption ? selectedOption.value : '' ,
            });
          };

          const handlecounrtyselect = (selectedOption) => {
            
            setFormData({
              ...formData,
              counrtryselect: selectedOption ? selectedOption.value : '' ,
            });
            //console.log(counrtryselect)
          };

          const handleplaceselect = (selectedOption) => {
            setFormData({
              ...formData,
              placeselect: selectedOption ? selectedOption.value : '' ,
            });
          };

          const handleSubmit = async (e) => {
            e.preventDefault();
            //alert();
            if (!validateForm()) {
                return; // Stop submission if validation fails
            }
        
            setIsLoading(true);
            let year_experince_vals = '';
            let month_experince_vals = '';
            if (workStatus === 'experience') {
                year_experince_vals = formData.totalexperince;
                month_experince_vals = formData.numbermonth;
            } else {
                year_experince_vals = 0;
                month_experince_vals = 0;
            }
        
            const data = new FormData(); 
            if (croppedimages !== "") {
                try {
                    const response = await fetch(croppedimages);
                    const blob = await response.blob();
                    const uniqueFileName = `croppedImage_image.jpeg`;
                    const file = new File([blob], uniqueFileName, { type: blob.type });
                    data.append('filename', file);
                } catch (error) {
                    console.error("Error in file processing: ", error);
                    setIsLoading(false);
                    return;
                }
            }
           
            data.append('first_name', formData.firstname);
            data.append('last_name', formData.lastname);
            data.append('phone_number', formData.phonenumber);
            data.append('email_id', formData.emailid);
            data.append('profile_summary', formData.profilesummary);
            data.append('country_val', formData.counrtryselect);
            data.append('place_val', formData.placeselect);
            data.append('year_experience', year_experince_vals);
            data.append('month_experince', month_experince_vals);
            data.append('work_status', workStatus);
            if(rows){
                data.append('work_experience', JSON.stringify(rows));
            }
            for (let [key, value] of data.entries()) {
                console.log(key, value);
            }
            try {
                const storeloginid = await localStorage.getItem('login_id');
                
                const response = await axios.post(`${baseURL}/addbasicfromdetails/${storeloginid}`, data, {
                    headers: {
                        'Content-Type': 'multipart/form-data',  // Ensure correct content type
                    },
                });
                //console.log('hi',response)
                if (response.data.status == 1) {
                    setPdfError('')
                    setMessage(response.data.message);
                    setIsSuccess(true);
                    setShowSuccessAlert(true);
                    setShowFiledAlert(false);
                    setTimeout(() => {
                        setIsLoading(false);
                        setValue((prevValue) => prevValue + 1);
                        window.scrollTo({
                            top: 10,
                            behavior: 'smooth', // smooth scroll to top
                        });
                    }, 2000);
                } else {
                    setMessage(response.data.message);
                    setIsSuccess(false);
                    setShowFiledAlert(true);
                    setShowSuccessAlert(false);
                }
            } catch (error) {
                console.error("Error submitting form: ", error);
                setMessage('Failed to add client. Please try again.');
                setIsSuccess(false);
                setShowFiledAlert(true);
                setShowSuccessAlert(false);
            } finally {
                //setIsLoading(false);
                fetchBasicDetails();
            }
        };
        
        
       //upload resume 
       const handleResume = async () => {
            
            if(!pdfFile){
                setPdfError('*choose resume')
                return false;
            }else{
                setIsLoading(true)
                setPdfError('')
                const formData = new FormData();
                formData.append('resume', pdfFile);
                
                
                try {
                    const storeloginid = localStorage.getItem('login_id');
                    const response = await axios.post(`${baseURL}/uploadResume/${storeloginid}`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    });
                    console.log(response)
                    if (response.data.status) {
                        fetchResume();
                        setMessage(response.data.message);
                        setIsSuccess(true);
                        setShowSuccessAlert(true);
                        setShowFiledAlert(false);
                        setTimeout(() => {
                            setValue((prevValue) => prevValue + 1)
                            setIsLoading(false)
                        },1000)
                        
                    } else {
                        setMessage(response.data.message);
                        setIsSuccess(false);
                        setShowFiledAlert(true);
                        setShowSuccessAlert(false);
                    }
                } catch (error) {
                console.log(error)
                }
                
            }
       }

// console.log(croppedimages)
             //validation
    const validateForm = () => {
        const newErrors = {};
        const errors = {};
        //Validate Job Title
        if(croppedimages=='' && globalState == ""){
            newErrors.cropedimagv = 'Please upload image'; 
        }

        if (!formData.firstname.trim()) {
            newErrors.firstname = 'First Name is required';
        }

        if (!formData.lastname.trim()) {
            newErrors.lastname = 'Last Name is required';
        }

        if (!formData.phonenumber.trim()) {
            newErrors.phonenumber = 'Phone number is required';
        }
        if (!formData.emailid.trim()) {
            newErrors.emailid = 'Email id is required';
        }
        if (!formData.profilesummary.trim()) {
            newErrors.profilesummary = 'Profile summary is required';
        }
        if (!formData.counrtryselect.trim()) {
            newErrors.counrtryselect = 'Country is required';
        }
        if (!formData.placeselect.trim()) {
            newErrors.placeselect = 'Place is required';
        }
        if(workStatus === 'experience'){
            rows.forEach((row, index) => {
                if (!row.startDate) {
                    newErrors[`startDate_${index}`] = 'Start date is required';
                    errors[`startDate_${index}`] = 'Start date is required';
                }
                
                if (!row.companyName.trim()) {
                    newErrors[`companyName_${index}`] = 'Company name is required';
                    errors[`companyName_${index}`] = 'Company name is required';
                }
                if (!row.position.trim()) {
                    newErrors[`position_${index}`] = 'Position is required';
                    errors[`position_${index}`] = 'Position is required';
                }
            });
        }
        setValidationErrors(errors);
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const hideAlertAfterDelay = () => {
        setTimeout(() => {
            setShowSuccessAlert(false);
            setShowFiledAlert(false);
            setMessage('');
        }, 10000); // 10000 milliseconds = 10 seconds
    };


    //person details

    const [formDataperson, setFormDataperson] = useState({
        gender_val: '',
        marital_status: '',
        day_val: '',
        month_val: '',
        year_val: '',
        language: [],
      });

      const handlepersondetails = async (e) => {
        e.preventDefault();
    
        const errors = {};
    
        // Validate gender
        if (!formDataperson.gender_val) {
            errors.gender = "Gender is required.";
        }
    
        // Validate marital status
        if (!formDataperson.marital_status) {
            errors.maritalStatus = "Marital status is required.";
        }
    
        // Validate date of birth
        if (!formDataperson.day_val || !formDataperson.month_val || !formDataperson.year_val) {
            errors.dateOfBirth = "Complete date of birth is required.";
        }
    
        // Validate permanent address
        console.log(formDataperson)
        if (!formDataperson.permenent_adress) {
            errors.permenentadress = "Permanent address is required.";
        }
        if (!formDataperson.home_town) {
            errors.hometown = "Home town is required.";
        }
    
        // Validate pincode
        const pincodeRegex = /^[0-9]{6}$/; // Example: 6-digit pincode
        if (!formDataperson.pincode_val || !pincodeRegex.test(formDataperson.pincode_val)) {
            errors.pincode = "A valid 6-digit pincode is required.";
        }
    
        // Validate languages
        // console.log(formDataperson.language.length)
        formDataperson.language.some(lang => console.log(lang.language_val,lang.proficiancy_val,lang))
        if (formDataperson.language.length === 0 || 
            formDataperson.language.some(lang => !lang.language_val || !lang.proficiancy_val)) {
            errors.languages = "At least one language with proficiency is required.";
        }
    
        
    
        setPersonalErrors(errors);
    
        if (Object.keys(errors).length === 0) {
            setIsLoading(true)
            // console.log("Form data:", formDataperson);
            const formData = new FormData();
            formData.append('gender_val', formDataperson.gender_val);
            formData.append('marital_status', formDataperson.marital_status);
            formData.append('day_val', formDataperson.day_val);
            formData.append('month_val', formDataperson.month_val);
            formData.append('year_val', formDataperson.year_val);
            formData.append('permenent_adress', formDataperson.permenent_adress);
            formData.append('home_town', formDataperson.home_town);
            formData.append('pincode_val', formDataperson.pincode_val);
        
            // Append languages as a JSON string
            formData.append('language', JSON.stringify(formDataperson.language));
                
                
                try {
                    const storeloginid = await localStorage.getItem('login_id');
                    const response = await axios.post(`${baseURL}/insertPersonalDetails/${storeloginid}`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    });
                     console.log(formDataperson)
                    // console.log(response.data)
                    if (response.data.status) {
                        setMessage(response.data.message);
                        setIsSuccess(true);
                        setShowSuccessAlert(true);
                        setShowFiledAlert(false);
                        setTimeout(() => {
                            setValue((prevValue) => prevValue + 1)
                            setIsLoading(false)
                        },1000)
                        
                    } else {
                        setMessage(response.data.message);
                        setIsSuccess(false);
                        setShowFiledAlert(true);
                        setShowSuccessAlert(false);
                    }
                    setTimeout(() => {
                        setIsLoading(false);
                    },1500)
                } catch (error) {
                console.log(error)
                }
            // Proceed with form submission
        } else {
            console.log("Validation errors:", errors);
        }
    };
    //fetch basic details
    const fetchBasicDetails = async () => {
        const storeloginid =  localStorage.getItem('login_id');
        if (!storeloginid) {
            console.error("Login ID not found in localStorage");
            return; 
        }

        try {
            const response = await axios.get(`${baseURL}/getBasicDetails/${storeloginid}`);
           // console.log("Full Response: ", response.data);
            
            if (response.data.status === 'success') {
                const data = response.data.data;
                //console.log("Fetched Data: ", data);
                
                setFormData({
                    firstname: data.first_name || '',
                    lastname: data.last_name || '',
                    phonenumber: data.phone_number || '',
                    emailid: data.email_id || '',
                    profilesummary: data.profile_summary || '',
                    counrtryselect: data.country_val || '',
                    placeselect: data.place_val || '',
                    totalexperince: data.year_experience || '',
                    numbermonth: data.month_experince || '',
                    work_status: data.work_status || '',
                });

                if (data.cropped_image) {
                    setGlobalState(mediaPath +data.cropped_image);
                }

                setWorkStatus(data.work_status);

                // Handle experience array properly
                const experienceData = data.experience || [];
                if (experienceData.length > 0) {
                    const formattedRows = experienceData.map(exp => ({
                        id: exp.id,
                        startDate: exp.start_date,
                        endDate: exp.end_date,
                        companyName: exp.name_company,
                        position: exp.job_position,
                    }));
                    setRows(formattedRows);
                } else {
                    //console.log("No experience data available.");
                }
                //console.log("new rows",rows)
                
            } else {
                console.error("Failed to fetch details: ", response.data.message);
            }
        } catch (err) {
            console.error("Error fetching basic details: ", err);
        }
    };

    


    //fetch resume
        const fetchResume = async () => {
            const storeloginid = localStorage.getItem('login_id');
            try {
                const response = await axios.get(`${baseURL}/getResume/${storeloginid}`);
                console.log(response.data)
                if (response.data.status == 1) {
                    const data = response.data.data;
                    // console.log(data.resume_pdf)
                    const filePath=data.resume_pdf;
                    const fileName = filePath.substring(filePath.lastIndexOf('/') + 1);
                    setIntailFile(mediaPath+data.resume_pdf)
                    setPdfFile(mediaPath + data.resume_pdf)
                    setreumenameval(fileName)
                } else {
                    // Handle error if needed
                }
            } catch (err) {
                //console.log(err);
                // Handle error if needed
            }
        };
    

    useEffect(() => {       
    
        fetchBasicDetails();
        fetchResume();
    }, []);     

    //console.log(pdfFile)
    //
    //alert(formDataperson.gender_val)
    //apply job section

    const handleapplysubmit = () => {
    const jobId = id;
    setIsLoading(true)
    axios.post(`${baseURL}/applyjobscandidate?job_id=${jobId}&login_id=${loginId}`).then(response =>{
         console.log(response.data)
        
        
        if(response.data.status == 1){
            setMessage(response.data.message);
            toast.success('Job Applied Succesfully')
            setIsSuccess(true);
            setShowSuccessAlert(true);
            setShowFiledAlert(false);
            setTimeout(() => {
                //setValue((prevValue) => prevValue + 1)
                navigate('/jobs')
            },2000)
        }
        else if(response.data.status == 2){
            setMessage(response.data.message);
            setIsSuccess(false);
            setShowFiledAlert(true);
            setShowSuccessAlert(false);
            toast.error('Job Already Applied');
        }
        else if(response.data.status == 3){
            toast.error('Please fill the profile and resume datas!');
        }
        else{
            setMessage(response.data.message);
            setIsSuccess(false);
            setShowFiledAlert(true);
            setShowSuccessAlert(false);
        }
        setTimeout(() => {
            setIsLoading(false);
        },1500)
    })
    .catch(error => {
        // handle error (e.g., show error message)
        // console.log(error)
        setMessage('Failed to add client. Please try again.');
        setIsSuccess(false);
        setShowFiledAlert(true);
         setShowSuccessAlert(false);
         hideAlertAfterDelay();
         setIsLoading(false);
    })
    }
    //education submit
    const validateEducation = () => {
        setEducationError([])
        let valid = true;
        if(education.EightthStandYes){
            // if(education.EightthStandYes === 'Yes'){
            //     if(!education.eightthSchool){
            //         setEducationError(prevEducation => ({
            //             ...prevEducation,
            //             ['eightthSchool']: 'Required'
            //         }));
            //         valid = false;
            //     }

            //     if(!education.EightCompleDate){
            //         setEducationError(prevEducation => ({
            //             ...prevEducation,
            //             ['EightCompleDate']: 'Required'
            //         }));
            //         valid = false;
            //     }
            // }

        }else{
            setEducationError(prevEducation => ({
                ...prevEducation,
                ['EightthStandYes']: 'You must select one of these options'
            }));
            valid = false;
        }

        if(education.tenthStandYes){
            // if(education.tenthStandYes === 'Yes'){
            //     if(!education.tenthSchool){
            //         setEducationError(prevEducation => ({
            //             ...prevEducation,
            //             ['tenthSchool']: 'Required'
            //         }));
            //         valid = false;
            //     }

            //     if(!education.tewlveCompleteDate){
            //         setEducationError(prevEducation => ({
            //             ...prevEducation,
            //             ['tewlveCompleteDate']: 'Required'
            //         }));
            //         valid = false;
            //     }
            // }

        }else{
            setEducationError(prevEducation => ({
                ...prevEducation,
                ['tenthStandYes']: 'You must select one of these options'
            }));
            valid = false;
        }

        if(education.twelvethStand){
            // if(education.twelvethStand === 'Yes'){
            //     if(!education.tenthSchool){
            //         setEducationError(prevEducation => ({
            //             ...prevEducation,
            //             ['twelveSchool']: 'Required'
            //         }));
            //         valid = false;
            //     }

            //     if(!education.twelvethStand){
            //         setEducationError(prevEducation => ({
            //             ...prevEducation,
            //             ['twelvethStand']: 'Required'
            //         }));
            //         valid = false;
            //     }
            // }

        }else{
            setEducationError(prevEducation => ({
                ...prevEducation,
                ['twelvethStand']: 'You must select one of these options'
            }));
            valid = false;
        }

        if(education.studyCollage){
            if(education.studyCollage !== 'didntAttend'){
                if(!education.NameOfCollege){
                    setEducationError(prevEducation => ({
                        ...prevEducation,
                        ['NameOfCollege']: 'Name of college is required'
                    }));
                    valid = false;
                }

                if(education.completedCourseBachelors){
                    if(education.completedCourseBachelors === 'Yes'){
                        if(!education.BachelorsCompleteDate){
                            setEducationError(prevEducation => ({
                                ...prevEducation,
                                ['BachelorsCompleteDate']: 'Completion date is required.'
                            }));
                            valid = false;
                        }
                    }else{
                        if(!education.bachelorReason){
                            setEducationError(prevEducation => ({
                                ...prevEducation,
                                ['BachelorsCompleteDate']: 'Reason required.'
                            }));
                            valid = false;
                        }
                    }
                }else{
                    setEducationError(prevEducation => ({
                        ...prevEducation,
                        ['completedCourseBachelors']: 'You must select one of these options'
                    }));
                    valid = false;
                }
                if(education.completedCourseMasters){
                    if(education.completedCourseMasters === 'Yes'){
                        if(!education.MastersCompleteDate){
                            setEducationError(prevEducation => ({
                                ...prevEducation,
                                ['MastersCompleteDate']: 'Completion date is required.'
                            }));
                            valid = false;
                        }
                        if(!education.MastersCollege){
                            setEducationError(prevEducation => ({
                                ...prevEducation,
                                ['MastersCollege']: 'College Name is required.'
                            }));
                            valid = false;
                        }
                        
                    }else{
                        if(!education.MastersReason){
                            setEducationError(prevEducation => ({
                                ...prevEducation,
                                ['MastersReason']: 'Reason required.'
                            }));
                            valid = false;
                        }
                        if(!education.MastersCollege){
                            setEducationError(prevEducation => ({
                                ...prevEducation,
                                ['MastersCollege']: 'College Name is required.'
                            }));
                            valid = false;
                        }
                    }
                }
                if(education.completedCoursePhd){
                    if(education.completedCoursePhd === 'Yes'){
                        if(!education.PhdCompleteDate){
                            setEducationError(prevEducation => ({
                                ...prevEducation,
                                ['PhdCompleteDate']: 'Completion date is required.'
                            }));
                            valid = false;
                        }
                        if(!education.PhdCollege){
                            setEducationError(prevEducation => ({
                                ...prevEducation,
                                ['PhdCollege']: 'College Name is required.'
                            }));
                            valid = false;
                        }

                    }else{
                        if(!education.PhdReason){
                            setEducationError(prevEducation => ({
                                ...prevEducation,
                                ['PhdReason']: 'Reason required.'
                            }));
                            valid = false;
                        }
                        if(!education.PhdCollege){
                            setEducationError(prevEducation => ({
                                ...prevEducation,
                                ['PhdCollege']: 'College Name is required.'
                            }));
                            valid = false;
                        }
                    }
                }
            }

        }else{
            setEducationError(prevEducation => ({
                ...prevEducation,
                ['studyCollage']: 'You must select one of these options'
            }));
            valid = false;
        }
        

        return valid;
        

        
    }
    const handleEducation = async () => {
        
        if(!validateEducation()){
            return false;
        }

        
        try{
            setIsLoading(true)
            const formData = new FormData();
            formData.append('eight_status',education.EightthStandYes)
            formData.append('tenth_status',education.tenthStandYes)
            formData.append('twelve_status',education.twelvethStand)
            formData.append('college_status',education.studyCollage)
            formData.append('college_name',education.NameOfCollege)
            formData.append('degree_status',education.completedCourseBachelors)
            education.BachelorsCompleteDate && formData.append('degree_date',education.BachelorsCompleteDate)
            education.bachelorReason && formData.append('degree_reason',education.bachelorReason)

            education.MastersCollege && formData.append('master_college',education.MastersCollege)
            education.completedCourseMasters && formData.append('master_status',education.completedCourseMasters)
            education.MastersCompleteDate && formData.append('master_date',education.MastersCompleteDate)
            education.MastersReason && formData.append('master_reason',education.MastersReason)

            education.PhdCollege && formData.append('phd_college',education.PhdCollege)
            education.completedCoursePhd && formData.append('phd_status',education.completedCoursePhd)
            education.PhdCompleteDate && formData.append('phd_date',education.PhdCompleteDate)
            education.PhdReason && formData.append('phd_reason',education.PhdReason)

            const storeloginid = await localStorage.getItem('login_id');
            const response = await axios.post(`${baseURL}/handleEducation/${storeloginid}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
                
            });
            //alert(response)
            console.log('hi',response)
            if(response.data.status == 1){
                setMessage(response.data.message);
                toast.success('Education Details Updated')
                setIsSuccess(true);
                setShowSuccessAlert(true);
                setShowFiledAlert(false);
                setTimeout(() => {
                    setIsLoading(false)
                    setValue((prevValue) => prevValue + 1)
                    //navigate('/jobs')
                },2000)
            }
            
        }catch(err){
            console.log(err)
            
        }
    }

    //fetch_education
    useEffect(()=>{
        const fetchEducation = async () => {
            const storeloginid = await localStorage.getItem('login_id');
            try {
                const response = await axios.get(`${baseURL}/Education/${storeloginid}`);
                //console.log('education',response)
                if (response.data.status == 1) {
                    const data = response.data.data;
                    setEducation({
                        EightthStandYes:data.eight_class_status || '',
                        tenthStandYes:data.tenth_class_status || '',
                        twelvethStand:data.twelth_class_status || '',
                        studyCollage:data.collage_status || '',

                        completedCourseBachelors:data.degree_completed_status || '',
                        BachelorsCompleteDate:data.degree_completed_date || '',
                        bachelorReason:data.degree_non_completed_reason || '',
                        NameOfCollege:data.degree_collage_name || '',

                        completedCourseMasters:data.master_completed_status || '',
                        MastersCompleteDate:data.master_completed_date || '',
                        MastersReason:data.master_non_completed_reason || '',
                        MastersCollege:data.master_collage_name || '',

                        completedCoursePhd:data.phd_completed_status || '',
                        PhdCompleteDate:data.phd_completed_date || '',
                        PhdReason:data.phd_non_completed_reason || '',
                        PhdCollege:data.phd_collage_name || '',
                    })
                } else {
                   
                }
            } catch (err) {
                //console.log(err);
                
            }
        };
    
        fetchEducation();
    },[])

    //fetch applied button text if applied or not
    const [applybutton,setapplybutton]=useState('');
    useEffect(()=>{
        const jobId = id;
        const login_id =localStorage.getItem('login_id');
        const fetchapplybutton=async ()=>{
            try{
                const response = await axios.get(`${baseURL}/applybuttoncheck`, {
                    params: { id: jobId,login_id:login_id },
                });
                //console.log(response.data)
                // setValue((prevValue) => {
                //     //console.log("Previous Value:", prevValue);  // This will log the current value before updating
                //     return prevValue + 1;
                // });
            setapplybutton(response.data.applybutton)
            if(response.data.review_status == 1){
                setValue(4)
            }
            // else{
            //     setValue((prevValue) => {
            //         //console.log("Previous Value:", prevValue);  // This will log the current value before updating
            //         console.log(prevValue)
            //         return prevValue + 1;
            //     });
            // }
            }
            catch(err){
                console.log(err)
            }
        }
        fetchapplybutton();
        
    }, [id]);



    return (
        <>
        {showSuccessPopup && (
            <div className="jobAppliedSuccessPopup">
                <div className="jobAppliedSuccessPopupMain">
                    <div className="jobAppliedSuccessPopupImg">
                        <img src={successImg} alt="" />
                    </div>
                    <div className="jobAppliedSuccessPopupDetails">
                        <h2>Job applied successfully</h2>
                        <p>Your application was submitted successfully. We’ll review it and contact you if you're selected.</p>
                        <Link to={`${path}/jobs`} className="okBtn" onClick={closeSuccessPopup}>Okay</Link>
                    </div>
                </div>
            </div>
            )}
            <section id="applyJobForm">
                <div className="container">
                    <BackBtn />
                    <div className="applyJobFormMain">
                        <div className="applyJobFormMenu">
                            <div className="applyJobFormMenuHead">
                                <h2 className="subTitle">Quick links</h2>
                            </div>
                            <div className="applyJobFormMenuBody">
                                <Tabs
                                orientation={isMobile ? "horizontal" : "vertical"}
                                    variant="scrollable"
                                    value={value}
                                    onChange={handleChange}
                                    aria-label="Vertical tabs example"
                                >
                                    {/* <Tab label="Profile" {...a11yProps(0)} />
                                    <Tab label="Resume" {...a11yProps(1)} />
                                    <Tab label="Education" {...a11yProps(2)} />
                                    <Tab label="Personal Details" {...a11yProps(3)} />
                                    <Tab label="Review" {...a11yProps(4)}/> */}

                                    <Tab label="Profile" onClick={() => setValue(0)} />
                                    <Tab label="Resume" onClick={() => setValue(1)} />
                                    <Tab label="Education" onClick={() => setValue(2)} />
                                    <Tab label="Personal Details" onClick={() => setValue(3)} />
                                    <Tab label="Review" onClick={() => setValue(4)} />
                                </Tabs>
                            </div>
                        </div>
                        <div className="applyJobFormList">
                            <TabPanel value={value} index={0}>
                                <div className="applyJobFormListBox">
                                    <div className="applyJobFormListBoxHead">
                                        <h2 className="subTitle">Profile Details</h2>
                                    </div>
                                    <div className="applyJobFormListBoxHeadBody">
                                        <form action="" >
                                            <div className="formGroup col-1">
                                                <label htmlFor="">Profile Image</label>
                                                <ProfileImage onCropCompleteLast={handleCroppedImage} initialImageSrc={globalState}/>
                                                {errors.cropedimagv && <p className="error">{errors.cropedimagv}</p>}
                                            </div>
                                            <div className="formGroup">
                                                <label htmlFor="">First Name</label>
                                                <input type="text" name="firstname" onKeyUp={handleChangev1} onChange={handleChangev1} value={formData.firstname}/>
                                                {errors.firstname && <p className="error">{errors.firstname}</p>}
                                            </div>
                                            <div className="formGroup">
                                                <label htmlFor="">Last Name</label>
                                                <input type="text" name="lastname" onKeyUp={handleChangev1} onChange={handleChangev1} value={formData.lastname}/>
                                                {errors.lastname && <p className="error">{errors.lastname}</p>}
                                            </div>
                                            <div className="formGroup">
                                                <label htmlFor="">Phone Number</label>
                                                <input type="number" name="phonenumber" onKeyUp={handleChangev1} onChange={handleChangev1} value={formData.phonenumber}/>
                                                {errors.phonenumber && <p className="error">{errors.phonenumber}</p>}
                                            </div>
                                            <div className="formGroup">
                                                <label htmlFor="">Email</label>
                                                <input type="email" name="emailid" onKeyUp={handleChangev1} onChange={handleChangev1} value={formData.emailid}/>
                                                {errors.emailid && <p className="error">{errors.emailid}</p>}
                                            </div>
                                            <div className="formGroup">
                                                <label htmlFor="">Work History</label>
                                                <div className="formRadioGroup">
                                                    <div className="formRadioGroupBox">
                                                        <input type="radio" id='fresher' name="workStatus" checked={workStatus === 'fresher'} 
                                                            onChange={handleWorkStatusChange} />
                                                        <label htmlFor="fresher">
                                                            <div className="radioIcon"></div>
                                                            <div className="radioText">Fresher</div>
                                                        </label>
                                                    </div>
                                                    <div className="formRadioGroupBox">
                                                        <input type="radio" id='experience' name="workStatus" checked={workStatus === "experience"} 
                                                            onChange={handleWorkStatusChange} />
                                                        <label htmlFor="experience">
                                                            <div className="radioIcon"></div>
                                                            <div className="radioText">Experience</div>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* {console.log(workStatus)} */}
                                            {workStatus === 'experience' && (
                                                <div className="formGroup col-1 experienceArea">
                                                    {/* <label htmlFor="">Total experience</label>
                                                    <div className="formGroupDorpdownList">
                                                        <div className="formGroupDorpdownListBox">
                                                            <Select
                                                                options={year}
                                                                placeholder="Number of Year"
                                                                isSearchable={true}
                                                                className="customSelect"
                                                                isClearable={isClearable}
                                                      F          styles={customStyles}
                                                                name="totalexperince"
                                                                
                                                                value={year.find(p=>p.value === formData.totalexperince)}
                                                                onChange={handletotalexperiance}
                                                            />
                                                            {errors.totalexperince && <p className="error">{errors.totalexperince}</p>}
                                                        </div>
                                                        {console.log(formData)}
                                                        <div className="formGroupDorpdownListBox">
                                                            <Select
                                                                options={month}
                                                                placeholder="Number of Month"
                                                                isSearchable={true}
                                                                className="customSelect"
                                                                isClearable={isClearable}
                                                                styles={customStyles}
                                                                name="numbermonth"
                                                                
                                                                value={month.find(p=>p.value === formData.numbermonth)}
                                                                onChange={handlenumbermonth}
                                                            />
                                                            {errors.numbermonth && <p className="error">{errors.numbermonth}</p>}
                                                        </div>
                                                        
                                                    </div> */}
                                                    <div className="formTable">
                                                        <div className="formTableHead">
                                                            <div className="formTableHeadBox">Start Date</div>
                                                            <div className="formTableHeadBox">End Date</div>
                                                            <div className="formTableHeadBox">Name of Organization / Company</div>
                                                            <div className="formTableHeadBox">Position</div>
                                                            <div className="formTableHeadBox"></div>
                                                        </div>
                                                        <div className="formTableBody">

                                                            {/* Render Rows Dynamically */}
                                                            {rows.map((row, index) => (
                                                                <div key={index} className="formTableBodyRow">
                                                                    <div className="formTableBodyBox">
                                                                        <label htmlFor="" className="formTableLabel">Start Date</label>
                                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                            <DatePicker
                                                                                views={['year', 'month']}
                                                                                value={row.startDate ? dayjs(row.startDate) : null}
                                                                                onChange={(newValue) => {
                                                                                    if (newValue && newValue.isValid()) {
                                                                                        const formattedValue = newValue.format('MMM YYYY');
                                                                                        updateRow(index, 'startDate', formattedValue);
                                                                                    }
                                                                                }}
                                                                                renderInput={(params) => <input {...params} />}
                                                                                format="MMM YYYY"
                                                                            />
                                                                        </LocalizationProvider>
                                                                        {validationErrors[`startDate_${index}`] && (
                                                                            <div className="error">{validationErrors[`startDate_${index}`]}</div>
                                                                        )}
                                                                    </div>

                                                                    <div className="formTableBodyBox">
                                                                        <label htmlFor="" className="formTableLabel">End Date</label>
                                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                            <DatePicker
                                                                                views={['year', 'month']}
                                                                                value={row.endDate ? dayjs(row.endDate) : null}
                                                                                onChange={(newValue) => {
                                                                                    if (newValue && newValue.isValid()) {
                                                                                        const formattedValue = newValue.format('MMM YYYY');
                                                                                        updateRow(index, 'endDate', formattedValue);
                                                                                    }
                                                                                }}
                                                                                renderInput={(params) => <input {...params} />}
                                                                                format="MMM YYYY"
                                                                            />
                                                                        </LocalizationProvider>
                                                                        {validationErrors[`endDate_${index}`] && (
                                                                            <div className="error">{validationErrors[`endDate_${index}`]}</div>
                                                                        )}
                                                                    </div>

                                                                    <div className="formTableBodyBox">
                                                                        <label htmlFor="" className="formTableLabel">Name of Organization / Company</label>
                                                                        <input
                                                                            type="text"
                                                                            value={row.companyName}
                                                                            onChange={(e) => updateRow(index, 'companyName', e.target.value)}
                                                                        />
                                                                        {validationErrors[`companyName_${index}`] && (
                                                                            <div className="error">{validationErrors[`companyName_${index}`]}</div>
                                                                        )}
                                                                    </div>

                                                                    <div className="formTableBodyBox">
                                                                        <label htmlFor="" className="formTableLabel">Position</label>
                                                                        <input
                                                                            type="text"
                                                                            value={row.position}
                                                                            onChange={(e) => updateRow(index, 'position', e.target.value)}
                                                                        />
                                                                        {validationErrors[`position_${index}`] && (
                                                                            <div className="error">{validationErrors[`position_${index}`]}</div>
                                                                        )}
                                                                    </div>

                                                                    <div className="formTableBodyBox">
                                                                        {index > 0 && (
                                                                            <button
                                                                                type="button"
                                                                                className="deleteformTableBodyRowBtn"
                                                                                onClick={() => removeRow(index,row.id)}
                                                                            >
                                                                                Delete
                                                                            </button>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            ))}
                                                            <div className="formTableBodyRow">
                                                                <div className="formTableBodyBox">
                                                                    <button type="button" className="addformTableBodyRowBtn" onClick={addRow} style={{marginLeft: 'auto'}}>
                                                                        Add More
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            {/* <div className="formGroup col-1">
                                                <label htmlFor="">Current location</label>
                                                <div className="formGroupDorpdownList">
                                                    <div className="formGroupDorpdownListBox">
                                                        <Select
                                                            options={locations}
                                                            placeholder="Select Country"
                                                            isSearchable={true}
                                                            className="customSelect"
                                                            isClearable={isClearable}
                                                            styles={customStyles}
                                                            name="counrtryselect"
                                                            onChange={handlecounrtyselect}
                                                            value={locations.find(p=>p.value === formData.counrtryselect)}
                                                        />
                                                        {errors.counrtryselect && <p className="error">{errors.counrtryselect}</p>}
                                                    </div>
                                                    
                                                    <div className="formGroupDorpdownListBox">
                                                        <Select
                                                            options={place}
                                                            placeholder="Select Place"
                                                            isSearchable={true}
                                                            className="customSelect"
                                                            isClearable={isClearable}
                                                            styles={customStyles}
                                                            name="placeselect"
                                                            onChange={handleplaceselect}
                                                            value={place.find(p=>p.value === formData.placeselect)}
                                                        />
                                                        {errors.placeselect && <p className="error">{errors.placeselect}</p>}
                                                    </div>
                                                    
                                                </div>
                                            </div> */}
                                            <div className="formGroup">
                                                <label htmlFor="">Country</label>
                                                <input type="text" name="counrtryselect" onKeyUp={handleChangev1} onChange={handleChangev1} value={formData.counrtryselect}/>
                                                {errors.counrtryselect && <p className="error">{errors.counrtryselect}</p>}
                                            </div>
                                            <div className="formGroup">
                                                <label htmlFor="">Place</label>
                                                <input type="text" name="placeselect" onKeyUp={handleChangev1} onChange={handleChangev1} value={formData.placeselect}/>
                                                {errors.placeselect && <p className="error">{errors.placeselect}</p>}
                                            </div>
                                            <div className="formGroup col-1">
                                                <label htmlFor="">Profile Summary</label>
                                                <textarea name="profilesummary" onKeyUp={handleChangev1} onChange={handleChangev1} value={formData.profilesummary}></textarea>
                                                {errors.profilesummary && <p className="error">{errors.profilesummary}</p>}
                                            </div>
                                        </form>
                                    </div>
                                    <div className="applyJobFormListBoxHeadFooter">
                                        {/* <div className="nextFormBtn" onClick={handleNextClick}>Submit Profile & Continue <PiCaretRightBold /></div> */}
                                        <button className="nextFormBtn" onClick={handleSubmit} disabled={isLoading}>{isLoading ? <Loader /> : 'Continue'}</button>
                                        {/* {isLoading ? <Loader /> : 'Save'} */}
                                    </div>
                                    
                                </div>
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <div className="applyJobFormListBox">
                                    <div className="applyJobFormListBoxHead">
                                        <h2 className="subTitle">Upload Resume</h2>
                                    </div>
                                    <div className="applyJobFormListBoxHeadBody">
                                        <form action="">
                                            <UploadResume onHandleImageUpload={handleImageUpload} existingResume={intailFile} reumenameval={reumenameval}/>
                                            {pdfError && <p className="error">{pdfError}</p>}
                                        </form>
                                    </div>
                                    <div className="applyJobFormListBoxHeadFooter">
                                        <div className="prevFormBtn" onClick={handlePrevClick}><PiCaretLeftBold /> Back</div>
                                        <div className="nextFormBtn" onClick={handleResume}>{isLoading ? <Loader /> : <>Continue <PiCaretRightBold /></>}
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel value={value} index={2}>
                                <div className="applyJobFormListBox">
                                    <div className="applyJobFormListBoxHead">
                                        <h2 className="subTitle">Education Details</h2>
                                    </div>
                                    <div className="applyJobFormListBoxHeadBody">
                                        <form action="">
                                            <EducationForm education={education} setEducation={setEducation} educationError={educationError} setEducationError={setEducationError}/>
                                        </form>
                                    </div>
                                    <div className="applyJobFormListBoxHeadFooter">
                                        <div className="prevFormBtn" onClick={handlePrevClick}><PiCaretLeftBold /> Back</div>
                                        <div className="nextFormBtn" onClick={handleEducation}disabled={isLoading}>{isLoading ? <Loader /> : <>Continue <PiCaretRightBold /></>}</div>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel value={value} index={3}>
                                <div className="applyJobFormListBox">
                                    <div className="applyJobFormListBoxHead">
                                        <h2 className="subTitle">Personal Details</h2>
                                    </div>
                                    <div className="applyJobFormListBoxHeadBody">
                                        <form action="">
                                            <PersonalDetailsForm formDataperson={formDataperson} setFormDataperson={setFormDataperson} personalErrors={personalErrors} setPersonalErrors={setPersonalErrors}/>
                                        </form>
                                    </div>
                                    <div className="applyJobFormListBoxHeadFooter">
                                        <div className="prevFormBtn" onClick={handlePrevClick}><PiCaretLeftBold /> Back</div>
                                        <div className="nextFormBtn" onClick={handlepersondetails} disabled={isLoading}> {isLoading ? <Loader /> : 'Continue'}<PiCaretRightBold /></div>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel value={value} index={4}>
                                <div className="applyJobFormListBox">
                                    <div className="applyJobFormListBoxHead">
                                        <h2 className="subTitle">Review & Apply</h2>
                                    </div>
                                    <div className="applyJobFormListBoxHeadBody">
                                        {/* {console.log(formDataperson)} */}
                                        <ReviewForm setValue={setValue} basicDatails={formData}   resumeFile={intailFile} experience={rows} education={education} />
                                    </div>
                                    <div className="applyJobFormListBoxHeadFooter">
                                        <div className="prevFormBtn" onClick={handlePrevClick}><PiCaretLeftBold /> Back</div>
                                        <button className="nextFormBtn" onClick={handleapplysubmit} disabled={isLoading || applybutton === 'Applied'}>{isLoading ? <Loader /> :applybutton}</button>
                                        {/* <div className="nextFormBtn" onClick={handleNextClick}>Apply Now <PiCaretRightBold /></div> */}
                                    </div>
                                </div>
                            </TabPanel>
                        </div>
                    </div>
                </div>
            </section>
            <HomeContact />
        </>
    );
}


export default ApplyJobForm;
