import React from "react";
import { Link, Element, scroller } from 'react-scroll';
import HomeContact from "../home/homeContact";

function Faq() {

  const scrollToSection = (id) => {
    scroller.scrollTo(id, {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart',
      offset: -90 // Adjust this value based on your header height
    });
  };
  
  return (
    <>
        <section id="faqSection">
            <div className="container">
                <div className="faqSectionMain">
                  <div className="faqSectionHead">
                      <h1 className="mainTitle">Frequently Asked Questions </h1>
                    </div>
                    <div className="faqSectionCol1">
                      <ul>
                        <li>
                          <Link 
                            to="jobApplication"
                            className="faqLink"
                            activeClass="faqLinkActive"
                            spy={true}
                            smooth={true}
                            offset={-90}
                            duration={500}
                            onClick={() => scrollToSection('jobApplication')}
                            >Job Application</Link>
                        </li>
                        <li>
                          <Link
                            to="labourCard"
                            className="faqLink"
                            activeClass="faqLinkActive"
                            spy={true}
                            smooth={true}
                            offset={-90}
                            duration={500}
                            onClick={() => scrollToSection('labourCard')}>Labour Card</Link>
                        </li>
                        <li>
                          <Link
                            to="designation"
                            className="faqLink"
                            activeClass="faqLinkActive"
                            spy={true}
                            smooth={true}
                            offset={-90}
                            duration={500}
                            onClick={() => scrollToSection('designation')}
                          >Changing of Designation</Link>
                        </li>
                        <li>
                          <Link
                            to="visaPassport"
                            className="faqLink"
                            activeClass="faqLinkActive"
                            spy={true}
                            smooth={true}
                            offset={-90}
                            duration={500}
                            onClick={() => scrollToSection('visaPassport')}
                          >Visa and Pasport Information</Link>
                        </li>
                        <li>
                          <Link
                            to="terms"
                            className="faqLink"
                            activeClass="faqLinkActive"
                            spy={true}
                            smooth={true}
                            offset={-90}
                            duration={500}
                            onClick={() => scrollToSection('terms')}
                          >Terms of Employment</Link>
                        </li>
                      </ul>
                    </div>
                    <div className="faqSectionCol2">
                      <div className="faqSectionCol2List">
                        <Element name="jobApplication" className="faqSectionCol2ListBox">
                          <h2 className="title">Job Application</h2>
                          <p>The value of air ticket allowance is decided by the client you are deputed at. Ticket allowance can be annual or biennial. Entitlement could range between RS 2000/- .<br></br>
                            This is subject to change as per company and client policy.</p>
                        </Element>
                        <Element name="labourCard" className="faqSectionCol2ListBox">
                          <h2 className="title">Labour Card</h2>
                          <ul>
                            <li>
                              <div className="icon"></div>
                              <h5>10 passport size photos with white background</h5>
                            </li>
                            <li>
                              <div className="icon"></div>
                              <h5>Clear colored passport copy (front and back) with a minimum of eight months’ validity</h5>
                            </li>
                            <li>
                              <div className="icon"></div>
                              <h5>Last academic certificate copy (attestation is required if two years have not been completed at the previous company)</h5>
                            </li>
                            <li>
                              <div className="icon"></div>
                              <h5>Cancellation documents, if any</h5>
                            </li>
                            <li>
                              <div className="icon"></div>
                              <h5>Emirates ID copy</h5>
                            </li>
                            <li>
                              <div className="icon"></div>
                              <h5>Visa Copy with at least one</h5>
                            </li>
                            <li>
                              <div className="icon"></div>
                              <h5>Year validity</h5>
                            </li>
                            <li>
                              <div className="icon"></div>
                              <h5>Sponsor (husband/father) Passport and visa page copies with both being valid for more than one year</h5>
                            </li>
                            <li>
                              <div className="icon"></div>
                              <h5>NOC from sponsor (husband/father)</h5>
                            </li>
                            {/* <li>
                              <div className="icon"></div>
                              <h5>Should be in Arabic</h5>
                            </li> */}
                          </ul>
                        </Element>
                        <Element name="designation" className="faqSectionCol2ListBox">
                          <h2 className="title">Changing of Designation</h2>
                          <div className="qustionBox">
                            <h3 className="qustion">How much does it cost and who will bear the cost?</h3>
                            <p>RS 680/- is the charge and employee bears the cost. At times, the client also bears the cost -This is subject to approval.</p>
                          </div>
                          <div className="qustionBox">
                            <h3 className="qustion">What is the process involved in change of designation, and what are the documents required?</h3>
                            <p>Get the approval from the client.<br />Provide the attested degree (Attested from home country and Ministry of Foreign Affairs)</p>
                          </div>
                          
                          <h3 className="subTitle">End of Service Benifits</h3>
                          <div className="qustionBox">
                            <h3 className="qustion">If get terminated from service for any reason (non-renewal of contract / low performance/fraud) do I still get the End of Service Benefits (EOSB) benefits?</h3>
                            <p>EOSB will be calculated and paid as per the Labor Law.</p>
                          </div>
                          <div className="qustionBox">
                            <h3 className="qustion">When can I expect to receive the End of Service Benefits (EOSB)?</h3>
                            <p>The EOSB will be credited once your visa is cancelled from immigration. The EOSB will be released on Monday or Thursday only.</p>
                          </div>
                        </Element>
                        <Element name="visaPassport" className="faqSectionCol2ListBox">
                          <h2 className="title">Visa Application and Passport Information</h2>
                          <div className="qustionBox">
                            <h3 className="qustion">What do I need to do if I want to sponsor my family?</h3>
                            <p>You can reach out to pro@innovationuae.com, and our dedicated staff will assist you regarding sponsoring family.</p>
                          </div>
                          <div className="qustionBox">
                            <h3 className="qustion">What do I need to do when my visa expires?</h3>
                            <h3 className="qustion" style={{ marginTop: '15px' }}>Situation 1:</h3>
                            <p>During your employment, if your visa expires, we will take the approval from the client to renew. Post confirmation, we shall proceed with the visa renewal process.</p>
                            <h3 className="qustion" style={{ marginTop: '15px' }}>Situation 2:</h3>
                            <p>During your resignation/termination/end of contract you’ll have 30 days’ grace period from the cancellation of your visa. Within these 30 days, you will need to apply for a fresh visa or exit the country.</p>
                          </div>
                          <div className="qustionBox">
                            <h3 className="qustion">How long does the visa stamping process take?</h3>
                            <p>It takes 7-10 working days for the stamping formalities. Subject to immigration approval.</p>
                          </div>
                          <div className="qustionBox">
                            <h3 className="qustion">How long does it take to complete the visa process?</h3>
                            <p>It takes 5-7 working days to complete the visa process. Subject to Ministry of Labor and Immigration Approval. *For classified nationality, it can go up to 15 days.</p>
                          </div>
                          <div className="qustionBox">
                            <h3 className="qustion">What are the documents required for the visa application process?</h3>
                            <ul>
                              <li>
                                <div className="icon"></div>
                                <h5>Ten passport size photos with white background</h5>
                              </li>
                              <li>
                                <div className="icon"></div>
                                <h5>Clear colored passport copy (front and back) with a minimum of eight months’ validity</h5>
                              </li>
                              <li>
                                <div className="icon"></div>
                                <h5>Last academic certificate copy (attestation is required if two years have not been completed at the previous company)</h5>
                              </li>
                              <li>
                                <div className="icon"></div>
                                <h5>Cancellation documents, if any</h5>
                              </li>
                              <li>
                                <div className="icon"></div>
                                <h5>Emirates ID copy</h5>
                              </li>
                            </ul>
                          </div>
                          <div className="qustionBox">
                            <h3 className="qustion">Emergency Contact</h3>
                            <p>Should there be an emergency your manager needs to call: Hakeem 0553919050 or Chandan 0558571556 and request to release the passport immediately. You will have to collect the passport from our office.</p>
                          </div>
                        </Element>
                        <Element name="terms" className="faqSectionCol2ListBox">
                            <h2 className="title">Terms of Employment </h2>
                            <div className="qustionBox">
                              <h3 className="qustion">What is the notice period?</h3>
                              <p>In the case of an unlimited Employment Contract’, either party (employer or employee) can terminate the contract with 30 days’ notice. In the case of a ‘Limited Employment Contract’, either party (employer or employee) may require to provide 3 months’ notice to each other to terminate the contract.</p>
                            </div>
                            <div className="qustionBox">
                              <h3 className="qustion">What is the policy for maternity leave?</h3>
                              <p>As per the Federal Labor Law UAE Article (30), a working woman is entitled to maternity leave with full pay for a period of forty five days(45 Days) including the time before and after delivery, provided that her continuous period of service with the employer is not less than a year(one year). But if a working woman has not completed the said period, the maternity leave shall be with half pay.</p>
                            </div>
                            <div className="qustionBox">
                              <h3 className="qustion">How much leave will I be entitled to annually?</h3>
                              <p>As per UAE Labor Law, you will be entitled to 30 calendar days of leave, annually.</p>
                            </div>
                            <div className="qustionBox">
                              <h3 className="qustion">What are the working hours and how does overtime get calculated?</h3>
                              <p>The maximum prescribed working hours for an adult employee is eight hours per day or forty-eight hours per week. However, the working hours may be increased to nine hours per day in the case of persons employed in trade, hotels, cafeterias, and as guards. Overtime is calculated as per UAE Labor Law.</p>
                            </div>
                        </Element>
                      </div>
                    </div>
                </div>
            </div>
        </section>

        <HomeContact />
    </>
  );
}

export default Faq;
