import React, { useState, useRef ,useEffect} from "react";
import { Link } from 'react-router-dom';
import { BiPhone } from "react-icons/bi";
import { MdOutlineAlternateEmail, MdOutlineCheckCircle, MdOutlineEdit } from "react-icons/md";
import { TbUserScan } from "react-icons/tb";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { RiDeleteBinLine, RiDownloadLine } from "react-icons/ri";
import { IoAdd } from "react-icons/io5";
import { toast } from 'react-toastify';
import { useDropzone } from 'react-dropzone';
import UploadResume from '../jobs/uploadResume';
import EducationForm from "../jobs/educationForm";
import PersonalDetailsForm from "../jobs/personalDetailsForm";
import ProfileImage from "./profileImage";
import axios from 'axios';
import api, { baseURL,mediaUrl } from '../axiosInterceptor/axiosInterceptorC';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import Loader from '../loader/buttonLoader';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useGlobalState } from '../globalProvider/globalProvider';
import placeHolderImg from '../../assets/images/user.png'

import Select from "react-select";
import dayjs from 'dayjs';
function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <div>{children}</div>
          </Box>
        )}
      </div>
    );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}
const mediaPath = mediaUrl

const locations = [
    { value: "new-york", label: "New York" },
    { value: "san-francisco", label: "San Francisco" },
    { value: "los-angeles", label: "Los Angeles" },
    // Add more options here
];

const place = [
    { value: "new-york", label: "New York" },
    { value: "san-francisco", label: "San Francisco" },
    { value: "los-angeles", label: "Los Angeles" },
    // Add more options here
];

const year = [
    { value: "1year", label: "1 Year" },
    { value: "2years", label: "2 Years" },
    { value: "3years", label: "3 Years" },
    { value: "4years", label: "4 Years" },
    { value: "5years", label: "5 Years" },
    { value: "6years", label: "6 Years" },
    { value: "7years", label: "7 Years" },
    { value: "8years", label: "8 Years" },
    { value: "9years", label: "9 Years" },
    { value: "10years", label: "10 Years" },
    { value: "10Above", label: "10+ Years" },
    // Add more options here
];

const month = [
    { value: "1month", label: "1 Month" },
    { value: "2month", label: "2 Months" },
    { value: "3month", label: "3 Months" },
    { value: "4month", label: "4 Months" },
    { value: "5month", label: "5 Months" },
    { value: "6month", label: "6 Months" },
    { value: "7month", label: "7 Months" },
    { value: "8month", label: "8 Months" },
    { value: "9month", label: "9 Months" },
    { value: "10month", label: "10 Months" },
    { value: "11month", label: "11 Months" },
    // Add more options here
];

function Profile(props) {

    const [resume, setResume] = React.useState(null);
    const [alertMessage, setAlertMessage] = React.useState('');
    const [isClearable] = useState(true);
    const [workStatus, setWorkStatus] = useState('fresher');
    const [resumeList, setResumeList] = useState([
        'Proposal for Zinnia Naturals.docx (1)_removed.pdf',
        'myresumeNew.pdf'
    ]);

    const [rows, setRows] = useState([{ startDate: null, endDate: null, companyName: '', position: '' }]); // Always have one row initially

    const [educationList, setEducationList] = useState([
        {
            id: 1,
            degree: 'Class 10th',
            board: 'CBSE',
            passingYear: '2024'
        },,
        {
            id: 2,
            degree: 'Class 12th',
            board: 'CBSE',
            passingYear: '2024'
        },
        {
            id: 3,
            degree: 'Masters / Post-Graduation',
            university: 'Harvard University',
            course: 'Computer Science',
            specialization: 'Machine Learning',
            courseType: 'Full Time',
            duration: '2020 - 2024'
        },
        {
            id: 4,
            degree: 'Doctorate / PhD',
            university: 'Harvard University',
            course: 'Computer Science',
            specialization: 'Machine Learning',
            courseType: 'Full Time',
            duration: '2020 - 2024'
        },
    ]);

    const [formDataprofile, setFormDataprofile] = useState({
        candidate_name:'',
        email_id:'',
        phone_number:'',
      });
    const [formData, setFormData] = useState({
        firstname:'',
        lastname:'',
        phonenumber:'',
        emailid:'',
        totalexperince:'',
        numbermonth:'',
        counrtryselect:'',
        placeselect:'',
        profilesummary:'',
      });
    const [formDataperson, setFormDataperson] = useState({
        gender_val: '',
        marital_status: '',
        day_val: '',
        month_val: '',
        year_val: '',
        language: [],
      });
    const { globalState, setGlobalState } = useGlobalState();
    const [intailFile,setIntailFile] = useState(null);
    const [inatialImage,setInatialImage] = useState();
    const [language,setLanguage] = useState([]);
    const [loginId,setLoginId] = useState(null);
    const [value, setValue] = useState(0);
    const [message, setMessage] = useState('');
    const [pdfFile,setPdfFile] = useState(null);
    const [pdfError,setPdfError] = useState(null);
    const [itemType, setItemType] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [inputs, setInputs] = useState({});
    const [errors, setErrors] = useState({});
    const [isSuccess, setIsSuccess] = useState(false);  
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [showFiledAlert, setShowFiledAlert] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const [isImageUploaded, setIsImageUploaded] = useState(false);
    const [croppedimages,setCroppedimage]=useState('');
    const [personalErrors, setPersonalErrors] = useState({});
    const [validationErrors, setValidationErrors] = useState({});
    const [isProfileImagePopupVisible, setIsProfileImagePopupVisible] = useState(false);
    const [isProfilePopupVisible, setIsProfilePopupVisible] = useState(false);
    const [IsregisterPopupVisible, setIsregisterPopupVisible] = useState(false);
    const [isResumePopupVisible, setIsResumePopupVisible] = useState(false);
    const [isDeleteResumePopupVisible, setIsDeleteResumePopupVisible] = useState(false);
    const [isEducationPopupVisible, setIsEducationPopupVisible] = useState(false);
    const [isPersonalPopupVisible, setIsPersonalPopupVisible] = useState(false);
    const [education,setEducation] = useState([]);
    const [educationError,setEducationError] = useState([]);
    const bodyRef = useRef(document.body);
    const applyJobFormListRef = useRef(null);

    const isMobile = useMediaQuery('(max-width:1024px)');

    const handleChange = (event, newValue) => {
        setValue(newValue);
        window.scrollTo(0, 0); // Scrolls to the top of the page
    };
    const handleChangev1 = (e) => {
        const name = e.target.name;
        const value = e.target.value.trim();
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
        setFormDataprofile({
            ...formDataprofile,
            [e.target.name]: e.target.value
        });
        const error = validate(name, value);
        setErrors((errors) => ({ ...errors, [name]: error[name] }));
    }
    const validate = (name, value) => {
        let error = {};
        if (name === 'job_title' && !value) {
            error.job_title = "Job title is required";
        } 
        return error;
    };

    const validateAllFields = (fields) => {
        let errors = {};
        for (let key in fields) {
            const error = validate(key, fields[key]);
            if (error[key]) {
                errors[key] = error[key];
            }
        }
        return errors;
    };

    const handleImageUpload = (file) => {
        setPdfFile(file);
        setIntailFile([file])
        
        // console.log(file)
        if (file) {
            setIsImageUploaded(true);
            setErrors(prev => ({ ...prev, serviceImage: '' }));
        } else {
            setIsImageUploaded(false);
       }
    };

    const handletotalexperiance = (selectedOption) => {
        setFormData({
          ...formData,
          totalexperince: selectedOption ? selectedOption.value : '' ,
        });
      };

      const handlenumbermonth = (selectedOption) => {
        setFormData({
          ...formData,
          numbermonth: selectedOption ? selectedOption.value : '' ,
        });
      };

      const handlecounrtyselect = (selectedOption) => {
        
        setFormData({
          ...formData,
          counrtryselect: selectedOption ? selectedOption.value : '' ,
        });
        //console.log(counrtryselect)
      };

      const handleplaceselect = (selectedOption) => {
        setFormData({
          ...formData,
          placeselect: selectedOption ? selectedOption.value : '' ,
        });
      };

    const handleDeleteClick = (item, type) => {
        setSelectedItem(item);
        setItemType(type);
        setIsPopupVisible(true);
        document.querySelector('.shimmer').style.display = 'block';
        if (bodyRef.current) bodyRef.current.style.overflow = 'hidden';
        if (applyJobFormListRef.current) applyJobFormListRef.current.style.position = 'inherit';
    };
//fetch register details
useEffect(() => {
    const fetchregisterdetails = async () => {
        try {
            const storeloginid = await localStorage.getItem('login_id');
            const response = await axios.get(`${baseURL}/getregisterdetails/${storeloginid}`);
            console.log(response.data);

            if (response.data.status === 'success') {
                const data = response.data.data;
                setFormDataprofile({
                    candidate_name: data.candidate_name || '',
                    email_id: data.email_id || '',
                    phone_number: data.phone_number || '',
                });
            } else {
                console.error('Fetch failed: ', response.data.message);
            }
        } catch (err) {
            console.error('Error fetching personal details:', err);
        }
    };

    fetchregisterdetails();
}, [loginId]);


    const fetchPersonalDetailsReview = async () => {
        try {
            const storeloginid = await localStorage.getItem('login_id');
            const response = await axios.get(`${baseURL}/getPersonalDetails/${storeloginid}`);
            //console.log(response);

            if (response.data.status === 'success') {
                const data = response.data.data;
                setFormDataperson({
                    gender_val: data.gender_val || '',
                    marital_status: data.marital_status || '',
                    day_val: data.day_val || '',
                    month_val: data.month_val || '',
                    year_val: data.year_val || '',
                    permenent_adress: data.permenent_adress || '',
                    home_town: data.home_town || '',
                    pincode_val: data.pincode_val || '',
                    language: data.language || [],
                });
                setLanguage(data.language)
            } else {
                console.error('Fetch failed: ', response.data.message);
            }
        } catch (err) {
            console.error('Error fetching personal details:', err);
        }
    };

    useEffect(() => {
        
    
        fetchPersonalDetailsReview();
    }, [loginId]);
    //fetch basic details
    const fetchData = async () => {
        const storeloginid = await localStorage.getItem('login_id');
        console.log("Fetching data for login ID:", storeloginid); // Log the login ID
        try {
            // Fetch Basic Details
            const responseBasic = await axios.get(`${baseURL}/getBasicDetails/${storeloginid}`);
            console.log("Basic Details Response:", responseBasic.data); // Log the basic details response
            
            if (responseBasic.data.status === 'success') {
                const data = responseBasic.data.data;
                const totalExperienceOption = year.find(option => option.value === `${data.year_experience}year` || option.value === `${data.year_experience}years`);
                const numberMonthOption = month.find(option => option.value === `${data.month_experince}month` || option.value === `${data.month_experince}months`);

                setFormData({
                    firstname: data.first_name || '',
                    lastname: data.last_name || '',
                    phonenumber: data.phone_number || '',
                    emailid: data.email_id || '',
                    profilesummary: data.profile_summary || '',
                    counrtryselect: data.country_val || '',
                    placeselect: data.place_val || '',
                    totalexperince: data.year_experience || '', // Set the entire object
                    numbermonth: data.month_experince || '', // Set the entire object
                    work_status: data.work_status || '',
                });
                setWorkStatus(data.work_status);
                if(data.cropped_image)
                    setGlobalState(mediaPath + data.cropped_image);
                const experienceData = data.experience || [];
                if (experienceData.length > 0) {
                    const formattedRows = experienceData.map(exp => ({
                        id: exp.id,
                        startDate: exp.start_date,
                        endDate: exp.end_date,
                        companyName: exp.name_company,
                        position: exp.job_position,
                    }));
                    setRows(formattedRows);
                } else {
                    console.log("No experience data available.");
                }
            } else {
                console.error("Failed to fetch basic details:", responseBasic.data.message); // Log the error message
            }

            // Fetch Resume
            const responseResume = await axios.get(`${baseURL}/getResume/${storeloginid}`);
            console.log("Resume Response:", responseResume.data); // Log the resume response
            //alert()
            if (responseResume.data.status === 1) {
                const data = responseResume.data.data;
                setIntailFile([mediaPath + data.resume_pdf]);
                setPdfFile(mediaPath + data.resume_pdf)
            } else {
                console.error("Failed to fetch resume:", responseResume.data.message); // Log the error message
            }
        } catch (err) {
            console.error("Error fetching data:", err); // Log the error
        }
    };
    useEffect(() => {
    

    fetchData();
}, [loginId]);

const handleCroppedImage = (blob) => {
    // console.log("Cropped Image Blob:", blob);
    setCroppedimage(blob);
    setGlobalState(blob);
    // Handle the blob value here, e.g., set it in state or send it to the backend
};

    //fetch resume
   
    //console.log(pdfFile)
    const handleCancelClick = () => {
        setIsPopupVisible(false);
        document.querySelector('.shimmer').style.display = 'none';
        if (bodyRef.current) bodyRef.current.style.overflow = 'inherit';
        if (applyJobFormListRef.current) applyJobFormListRef.current.style.position = 'sticky';
    };

    const handleConfirmDeleteClick = () => {
        if (itemType === 'resume') {
            setResumeList(prevList => prevList.filter(resume => resume !== selectedItem));
            toast.success('Resume Deleted Successfully!', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: 'light',
            });
        } else if (itemType === 'education') {
            setEducationList(prevList => prevList.filter(education => education.id !== selectedItem.id));
            toast.success('Education Detail Deleted Successfully!', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: 'light',
            });
        }
        setSelectedItem(null);
        setIsPopupVisible(false);
        document.querySelector('.shimmer').style.display = 'none';
        if (bodyRef.current) bodyRef.current.style.overflow = 'inherit';
        if (applyJobFormListRef.current) applyJobFormListRef.current.style.position = 'sticky';
    };

    const profileImageBtnClick = () => {
        setIsProfileImagePopupVisible(true);
        document.querySelector('.shimmer').style.display = 'block';
        if (bodyRef.current) bodyRef.current.style.overflow = 'hidden';
    };

    const handleWorkStatusChange = (event) => {
        setWorkStatus(event.target.id); // 'fresher' or 'experience'
    };

    const editregisterClick = () => {
        setIsregisterPopupVisible(true);
        document.querySelector('.shimmer').style.display = 'block';
        if (bodyRef.current) bodyRef.current.style.overflow = 'hidden';
    };
    
    const editProfileClick = () => {
        setIsProfilePopupVisible(true);
        document.querySelector('.shimmer').style.display = 'block';
        if (bodyRef.current) bodyRef.current.style.overflow = 'hidden';
    };

    const handleProfileCancelClick = () => {
        setIsregisterPopupVisible(false);
        setIsProfilePopupVisible(false);
        setIsResumePopupVisible(false);
        setIsEducationPopupVisible(false);
        setIsPersonalPopupVisible(false);
        setIsProfileImagePopupVisible(false);
        document.querySelector('.shimmer').style.display = 'none';
        if (bodyRef.current) bodyRef.current.style.overflow = 'inherit';
    };

    const handleProfileSubmitClick = () => {
        if (IsregisterPopupVisible) {
            toast.success("Register Details Updated Successfully!");
            setIsregisterPopupVisible(false);
        }
        if (isProfileImagePopupVisible) {
            toast.success("Profile Image Updated Successfully!");
            setIsProfileImagePopupVisible(false);
        }
        if (isProfilePopupVisible) {
            toast.success("Profile Details Updated Successfully!");
            setIsProfilePopupVisible(false);
        }
        if (isResumePopupVisible) {
            toast.success("Resume Updated Successfully!");
            setIsResumePopupVisible(false);
        }
        if (isEducationPopupVisible) {
            toast.success("Education Details Updated Successfully!");
            setIsEducationPopupVisible(false);
        }
        if (isPersonalPopupVisible) {
            toast.success("Personal Details Updated Successfully!");
            setIsPersonalPopupVisible(false);
        }
    
        // Hide shimmer effect
        document.querySelector('.shimmer').style.display = 'none';
    
        // Reset overflow
        if (bodyRef.current) bodyRef.current.style.overflow = 'inherit';
    };

    const editResumeClick = () => {
        setIsResumePopupVisible(true);
        document.querySelector('.shimmer').style.display = 'block';
        if (bodyRef.current) bodyRef.current.style.overflow = 'hidden';
    }

    const customStyles = {
        menu: (provided) => ({
            ...provided,
            zIndex: 100,
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#d2e2ff' : state.isFocused ? '#eee' : 'white',
            color: state.isSelected ? '#000' : 'inherit',
        }),
        singleValue: (provided) => ({
            ...provided,
            color: '#000',
        }),
    };

    const onDrop = (acceptedFiles) => {
        const file = acceptedFiles[0];
        const isValidFileType = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'].includes(file.type);
        const isValidFileSize = file.size <= 2 * 1024 * 1024; // 2MB
    
        if (isValidFileType && isValidFileSize) {
            setResume(file);
            setAlertMessage(''); // Clear alert message on valid upload
        } else {
            setAlertMessage('Please upload a valid file type (doc, docx, rtf, pdf) with size less than 2MB.');
        }
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: '.doc,.docx,.pdf,.rtf',
        maxSize: 2 * 1024 * 1024,
    });

    const handleResumeDeleteSubmitClick = () => {
        setIsDeleteResumePopupVisible(true);
        setIsResumePopupVisible(false);
        document.querySelector('.shimmer').style.display = 'block';
        if (bodyRef.current) bodyRef.current.style.overflow = 'hidden';
    };

    const handleResumeDeleteCancelClick = () => {
        setIsDeleteResumePopupVisible(false);
        setIsResumePopupVisible(true);
    };

    const hideAlertAfterDelay = () => {
        setTimeout(() => {
            setShowSuccessAlert(false);
            setShowFiledAlert(false);
            setMessage('');
        }, 10000); // 10000 milliseconds = 10 seconds
    };

    const handleResumeDeleteConfirmDeleteClick = () => {
        setResume(null);  // Clear the resume
        setIsDeleteResumePopupVisible(false);
        document.querySelector('.shimmer').style.display = 'none';
        if (bodyRef.current) bodyRef.current.style.overflow = 'inherit';
        toast.success('Resume Deleted Successfully!', {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: 'light',
        });
    };
    
    const editEducationClick = () => {
        setIsEducationPopupVisible(true);
        document.querySelector('.shimmer').style.display = 'block';
        if (bodyRef.current) bodyRef.current.style.overflow = 'hidden';
    };
    
    const editPersonalClick = () => {
        setIsPersonalPopupVisible(true);
        document.querySelector('.shimmer').style.display = 'block';
        if (bodyRef.current) bodyRef.current.style.overflow = 'hidden';
    };
    //handle submit
    const handleregisterSubmit=async (e) => {
        e.preventDefault();
        setIsLoading(true)
        const data = new FormData();
        data.append('candidate_name', formDataprofile.candidate_name);
        data.append('email_id', formDataprofile.email_id);
        data.append('phone_number', formDataprofile.phone_number);
        const storeloginid = localStorage.getItem('login_id');
            axios.post(`${baseURL}/editregister/${storeloginid}`, data).then(response =>{
                console.log(response.data)
                if(response.data.status == 1){
                    toast.success("Register Details Updated Successfully!");
                    setIsregisterPopupVisible(false);
                    document.querySelector('.shimmer').style.display = 'none';
                    if (bodyRef.current) bodyRef.current.style.overflow = 'inherit';
                    setMessage(response.data.message);
                    setIsSuccess(true);
                    setShowSuccessAlert(true);
                    setShowFiledAlert(false);
                    setTimeout(() => {
                        setIsregisterPopupVisible(false);
                        document.querySelector('.shimmer').style.display = 'none';
                        if (bodyRef.current) bodyRef.current.style.overflow = 'inherit';
                    },2000)
                }else{
                    setMessage(response.data.message);
                    setIsSuccess(false);
                    setShowFiledAlert(true);
                    
                    setShowSuccessAlert(false);
                }
                setTimeout(() => {
                    setIsLoading(false);
                },1500)
            }).catch(error => {
                // handle error (e.g., show error message)
                // console.log(error)
                setMessage('Failed to add client. Please try again.');
                setIsSuccess(false);
                setShowFiledAlert(true);
                setShowSuccessAlert(false);
                hideAlertAfterDelay();
                setIsLoading(false);
            })
           
    }

    const handleSubmit = async (e) => {
        console.log(loginId)
        e.preventDefault();
        if (!validateForm()) {
            return; // Stop submission if validation fails
        }
        setIsLoading(true)
        let year_experince_vals='';
        let month_experince_vals='';
        if(workStatus=='experience'){
            year_experince_vals=formData.totalexperince
            month_experince_vals=formData.numbermonth
        }
        else{
            year_experince_vals=0;
            month_experince_vals=0;
        }

        //const data = new FormData(); 
        const data = new FormData(); 
        if (croppedimages !== "") {
            try {
                const response = await fetch(croppedimages);
                const blob = await response.blob();
                const uniqueFileName = `croppedImage_image.jpeg`;
                const file = new File([blob], uniqueFileName, { type: blob.type });
                data.append('filename', file);
            } catch (error) {
                console.error("Error in file processing: ", error);
                setIsLoading(false);
                return;
            }
        }
            
        data.append('first_name', formData.firstname);
        data.append('last_name', formData.lastname);
        data.append('phone_number', formData.phonenumber);
        data.append('email_id', formData.emailid);
        data.append('profile_summary', formData.profilesummary);
        data.append('country_val', formData.counrtryselect);
        data.append('place_val', formData.placeselect);
        data.append('year_experience', year_experince_vals);
        data.append('month_experince',month_experince_vals);
        data.append('work_status',workStatus)
        
        if(rows){
            data.append('work_experience', JSON.stringify(rows));
        }

        const validationErrors = validateAllFields(inputs);
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            setMessage('Please correct the errors and try again.');
            setIsSuccess(false);
            setShowFiledAlert(true);
            setShowSuccessAlert(false);
            setIsLoading(false);
        } else{
            const storeloginid = localStorage.getItem('login_id');
            axios.post(`${baseURL}/addbasicfromdetails/${storeloginid}`, data).then(response =>{
            console.log(response.data)
            
                
                if(response.data.status == 1){
                    toast.success("Profile Details Updated Successfully!");
                    setIsProfilePopupVisible(false);
                    document.querySelector('.shimmer').style.display = 'none';
                    if (bodyRef.current) bodyRef.current.style.overflow = 'inherit';
                    setMessage(response.data.message);
                    setIsSuccess(true);
                    setShowSuccessAlert(true);
                    setShowFiledAlert(false);
                    setTimeout(() => {
                        setIsProfilePopupVisible(false);
                        document.querySelector('.shimmer').style.display = 'none';
                        if (bodyRef.current) bodyRef.current.style.overflow = 'inherit';
                    },2000)
                }else{
                    setMessage(response.data.message);
                    setIsSuccess(false);
                    setShowFiledAlert(true);
                    
                    setShowSuccessAlert(false);
                }
                setTimeout(() => {
                    setIsLoading(false);
                },1500)
            }).catch(error => {
                // handle error (e.g., show error message)
                // console.log(error)
                setMessage('Failed to add client. Please try again.');
                setIsSuccess(false);
                setShowFiledAlert(true);
                setShowSuccessAlert(false);
                hideAlertAfterDelay();
                setIsLoading(false);
            })
            fetchData();
        }
    };
    const validateForm = () => {
        const newErrors = {};
        //Validate Job Title
        const errors = {};
        if(croppedimages=='' && globalState ==""){
            newErrors.cropedimagv = 'Please upload image'; 
        }

        if (!formData.firstname.trim()) {
            newErrors.firstname = 'First Name is required';
        }

        if (!formData.lastname.trim()) {
            newErrors.lastname = 'Last Name is required';
        }

        if (!formData.phonenumber.trim()) {
            newErrors.phonenumber = 'Phone number is required';
        }
        if (!formData.emailid.trim()) {
            newErrors.emailid = 'Email id is required';
        }
        if (!formData.profilesummary.trim()) {
            newErrors.profilesummary = 'Profile summary is required';
        }
        if (!formData.counrtryselect.trim()) {
            newErrors.counrtryselect = 'Country is required';
        }
        if (!formData.placeselect.trim()) {
            newErrors.placeselect = 'Place is required';
        }
        if(workStatus === 'experience'){
            rows.forEach((row, index) => {
                if (!row.startDate) {
                    newErrors[`startDate_${index}`] = 'Start date is required';
                    errors[`startDate_${index}`] = 'Start date is required';
                }
                
                if (!row.companyName.trim()) {
                    newErrors[`companyName_${index}`] = 'Company name is required';
                    errors[`companyName_${index}`] = 'Company name is required';
                }
                if (!row.position.trim()) {
                    newErrors[`position_${index}`] = 'Position is required';
                    errors[`position_${index}`] = 'Position is required';
                }
            });
        }
        setValidationErrors(errors);
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    //upload resume 
    const fetchResume = async () => {
        const storeloginid = localStorage.getItem('login_id');
        const responseResume = await axios.get(`${baseURL}/getResume/${storeloginid}`);
            console.log("Resume Response:", responseResume.data); // Log the resume response
           // alert()
            if (responseResume.data.status === 1) {
                const data = responseResume.data.data;
                setIntailFile([mediaPath + data.resume_pdf]);
                setPdfFile(mediaPath + data.resume_pdf)
                
            } else {
                console.error("Failed to fetch resume:", responseResume.data.message); // Log the error message
            }
    }
    const handleResume = async () => {
        setIsLoading(true)
        if(!pdfFile){
            setPdfError('*choose resume')
            return false;
        }else{
            const formData = new FormData();
            formData.append('resume', pdfFile);
            
            
            try {
                const storeloginid = localStorage.getItem('login_id');
                console.log(storeloginid)
                const response = await axios.post(`${baseURL}/uploadResume/${storeloginid}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                //console.log(response)
                if (response.data.status) {
                    toast.success("Resume Updated Successfully!");
                    setMessage(response.data.message);
                    setIsSuccess(true);
                    setShowSuccessAlert(true);
                    setShowFiledAlert(false);
                    setTimeout(() => {
                        setIsResumePopupVisible(false);
                        // Log the resume response
                        //alert()
                        fetchResume();
                        document.querySelector('.shimmer').style.display = 'none';
                        if (bodyRef.current) bodyRef.current.style.overflow = 'inherit';
                       
                    },1000)
                    
                } else {
                    setMessage(response.data.message);
                    setIsSuccess(false);
                    setShowFiledAlert(true);
                    setShowSuccessAlert(false);
                }
            } catch (error) {
            console.log(error)
            }
            setIsLoading(false)
            
        }
   }

   //person details


    const handlepersondetails = async (e) => {
        e.preventDefault();

        const errors = {};

        // Validate gender
        if (!formDataperson.gender_val) {
            errors.gender = "Gender is required.";
        }

        // Validate marital status
        if (!formDataperson.marital_status) {
            errors.maritalStatus = "Marital status is required.";
        }

        // Validate date of birth
        if (!formDataperson.day_val || !formDataperson.month_val || !formDataperson.year_val) {
            errors.dateOfBirth = "Complete date of birth is required.";
        }

        // Validate permanent address
        //console.log(formDataperson)
        if (!formDataperson.permenent_adress) {
            errors.permenentadress = "Permanent address is required.";
        }
        if (!formDataperson.home_town) {
            errors.hometown = "Home town is required.";
        }

        // Validate pincode
        const pincodeRegex = /^[0-9]{6}$/; // Example: 6-digit pincode
        if (!formDataperson.pincode_val || !pincodeRegex.test(formDataperson.pincode_val)) {
            errors.pincode = "A valid 6-digit pincode is required.";
        }

        // Validate languages
        // console.log(formDataperson.language.length)
        formDataperson.language.some(lang => console.log(lang.language_val,lang.proficiancy_val,lang))
        if (formDataperson.language.length === 0 || 
            formDataperson.language.some(lang => !lang.language_val || !lang.proficiancy_val)) {
            errors.languages = "At least one language with proficiency is required.";
        }

        

        setPersonalErrors(errors);

        if (Object.keys(errors).length === 0) {
            setIsLoading(true)
            // console.log("Form data:", formDataperson);
            const formData = new FormData();
            formData.append('gender_val', formDataperson.gender_val);
            formData.append('marital_status', formDataperson.marital_status);
            formData.append('day_val', formDataperson.day_val);
            formData.append('month_val', formDataperson.month_val);
            formData.append('year_val', formDataperson.year_val);
            formData.append('permenent_adress', formDataperson.permenent_adress);
            formData.append('home_town', formDataperson.home_town);
            formData.append('pincode_val', formDataperson.pincode_val);
        
            // Append languages as a JSON string
            formData.append('language', JSON.stringify(formDataperson.language));
                
                
                try {
                    const storeloginid = localStorage.getItem('login_id');
                    const response = await axios.post(`${baseURL}/insertPersonalDetails/${storeloginid}`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    });
                    // console.log(formDataperson)
                    // console.log(response.data)
                    if (response.data.status) {
                        setMessage(response.data.message);
                        setIsSuccess(true);
                        setShowSuccessAlert(true);
                        setShowFiledAlert(false);
                        setTimeout(() => {
                            setIsPersonalPopupVisible(false);
                            document.querySelector('.shimmer').style.display = 'none';
                            if (bodyRef.current) bodyRef.current.style.overflow = 'inherit';
                            setIsLoading(false)
                            
                        },1000)
                        
                    } else {
                        setMessage(response.data.message);
                        setIsSuccess(false);
                        setShowFiledAlert(true);
                        setShowSuccessAlert(false);
                    }
                    setTimeout(() => {
                        setIsLoading(false);
                    },1500)
                } catch (error) {
                console.log(error)
                }
                fetchPersonalDetailsReview()
            // Proceed with form submission
        } else {
            console.log("Validation errors:", errors);
        }
    };
    const profilePhoto = async () => {
        const newErrors = {};
        setIsLoading(true);
        if(croppedimages!='' || globalState != ""){
            const data = new FormData(); 
            if (croppedimages !== "") {
                try {
                    const response = await fetch(croppedimages);
                    const blob = await response.blob();
                    const uniqueFileName = `croppedImage_image.jpeg`;
                    const file = new File([blob], uniqueFileName, { type: blob.type });
                    data.append('filename', file);
                } catch (error) {
                    console.error("Error in file processing: ", error);
                    setIsLoading(false);
                    return;
                }
            }
            const storeloginid = localStorage.getItem('login_id');
            axios.post(`${baseURL}/update_image/${storeloginid}`, data).then(response =>{
                console.log(response.data)
                
                    if(response.data.status == 1){
                        toast.success("Profile Image Updated Successfully!");
                        
                        setMessage(response.data.message);
                        setIsSuccess(true);
                        setShowSuccessAlert(true);
                        setShowFiledAlert(false);
                        setTimeout(() => {
                            setIsProfileImagePopupVisible(false);
                            document.querySelector('.shimmer').style.display = 'none';
                            if (bodyRef.current) bodyRef.current.style.overflow = 'inherit';
                        },2000)
                    }else{
                        setMessage(response.data.message);
                        setIsSuccess(false);
                        setShowFiledAlert(true);
                        setShowSuccessAlert(false);
                    }
                    setTimeout(() => {
                        setIsLoading(false);
                    },1500)
                }).catch(error => {
                    // handle error (e.g., show error message)
                    console.log(error)
                    setMessage('Failed to add client. Please try again.');
                    setIsSuccess(false);
                    setShowFiledAlert(true);
                    setShowSuccessAlert(false);
                    hideAlertAfterDelay();
                    setIsLoading(false);
                })
        }else{
            newErrors.cropedimagv = 'Please upload image'; 
        }
    }

    // Function to handle adding a new row
    const addRow = () => {
        setRows([...rows, { startDate: null, endDate: null, companyName: '', position: '' }]);
    };

    // Function to handle removing a row
    const removeRow = async (index,expId) => {
        const newRows = rows.filter((row, i) => i !== index);
        if (newRows.length === 0) {
            // Ensure there's always at least one row
            setRows([{ startDate: null, endDate: null, companyName: '', position: '' }]);
        } else {
            setRows(newRows);
        }
        try{
            const response = await axios.get(`${baseURL}/deleteExperience/${expId}`);
            console.log(response)
            
        }catch(err){
            console.log(err)
        }
    };

    // Function to update row data
    const updateRow = (index, field, value) => {
        console.log(value);
        const updatedRows = [...rows];
        updatedRows[index][field] = value; 
        setRows(updatedRows);
        setFormData(prevFormData => ({
            ...prevFormData,
            experienceArray: updatedRows,
        }));
        let errors = { ...validationErrors };
        if (field === 'startDate') {
            if (!value) {
                errors[`startDate_${index}`] = 'Start date is required';
            } else {
                delete errors[`startDate_${index}`];
            }
        
            // If endDate is present, validate that it's after startDate
            if (updatedRows[index].endDate && dayjs(updatedRows[index].endDate).isBefore(dayjs(value))) {
                errors[`endDate_${index}`] = 'End date cannot be before start date';
            } else {
                delete errors[`endDate_${index}`];
            }
        }
        
        // Validation for end date only if it's present
        if (field === 'endDate' && value) {
            if (updatedRows[index].startDate && dayjs(value).isBefore(dayjs(updatedRows[index].startDate))) {
                errors[`endDate_${index}`] = 'End date cannot be before start date';
            } else {
                delete errors[`endDate_${index}`];
            }
        }
        
        if (field === 'companyName' && !value.trim()) {
            errors[`companyName_${index}`] = 'Company name is required';
        } else if (field === 'companyName') {
            delete errors[`companyName_${index}`];
        }
        if (field === 'position' && !value.trim()) {
            errors[`position_${index}`] = 'Position is required';
        } else if (field === 'position') {
            delete errors[`position_${index}`];
        }
        
        setValidationErrors(errors);
    };

    //education submit
    const validateEducation = () => {
        setEducationError([])
        let valid = true;
        if(education.EightthStandYes){
            // if(education.EightthStandYes === 'Yes'){
            //     if(!education.eightthSchool){
            //         setEducationError(prevEducation => ({
            //             ...prevEducation,
            //             ['eightthSchool']: 'Required'
            //         }));
            //         valid = false;
            //     }

            //     if(!education.EightCompleDate){
            //         setEducationError(prevEducation => ({
            //             ...prevEducation,
            //             ['EightCompleDate']: 'Required'
            //         }));
            //         valid = false;
            //     }
            // }

        }else{
            setEducationError(prevEducation => ({
                ...prevEducation,
                ['EightthStandYes']: 'You must select one of these options'
            }));
            valid = false;
        }

        if(education.tenthStandYes){
            // if(education.tenthStandYes === 'Yes'){
            //     if(!education.tenthSchool){
            //         setEducationError(prevEducation => ({
            //             ...prevEducation,
            //             ['tenthSchool']: 'Required'
            //         }));
            //         valid = false;
            //     }

            //     if(!education.tewlveCompleteDate){
            //         setEducationError(prevEducation => ({
            //             ...prevEducation,
            //             ['tewlveCompleteDate']: 'Required'
            //         }));
            //         valid = false;
            //     }
            // }

        }else{
            setEducationError(prevEducation => ({
                ...prevEducation,
                ['tenthStandYes']: 'You must select one of these options'
            }));
            valid = false;
        }

        if(education.twelvethStand){
            // if(education.twelvethStand === 'Yes'){
            //     if(!education.tenthSchool){
            //         setEducationError(prevEducation => ({
            //             ...prevEducation,
            //             ['twelveSchool']: 'Required'
            //         }));
            //         valid = false;
            //     }

            //     if(!education.twelvethStand){
            //         setEducationError(prevEducation => ({
            //             ...prevEducation,
            //             ['twelvethStand']: 'Required'
            //         }));
            //         valid = false;
            //     }
            // }

        }else{
            setEducationError(prevEducation => ({
                ...prevEducation,
                ['twelvethStand']: 'You must select one of these options'
            }));
            valid = false;
        }

        if(education.studyCollage){
            if(education.studyCollage !== 'didntAttend'){
                if(!education.NameOfCollege){
                    setEducationError(prevEducation => ({
                        ...prevEducation,
                        ['NameOfCollege']: 'Name of college is required'
                    }));
                    valid = false;
                }

                if(education.completedCourseBachelors){
                    if(education.completedCourseBachelors === 'Yes'){
                        if(!education.BachelorsCompleteDate){
                            setEducationError(prevEducation => ({
                                ...prevEducation,
                                ['BachelorsCompleteDate']: 'Completion date is required.'
                            }));
                            valid = false;
                        }
                    }else{
                        if(!education.bachelorReason){
                            setEducationError(prevEducation => ({
                                ...prevEducation,
                                ['BachelorsCompleteDate']: 'Reason required.'
                            }));
                            valid = false;
                        }
                    }
                }else{
                    setEducationError(prevEducation => ({
                        ...prevEducation,
                        ['completedCourseBachelors']: 'You must select one of these options'
                    }));
                    valid = false;
                }
                if(education.completedCourseMasters){
                    if(education.completedCourseMasters === 'Yes'){
                        if(!education.MastersCompleteDate){
                            setEducationError(prevEducation => ({
                                ...prevEducation,
                                ['MastersCompleteDate']: 'Completion date is required.'
                            }));
                            valid = false;
                        }
                        if(!education.MastersCollege){
                            setEducationError(prevEducation => ({
                                ...prevEducation,
                                ['MastersCollege']: 'College Name is required.'
                            }));
                            valid = false;
                        }
                        
                    }else{
                        if(!education.MastersReason){
                            setEducationError(prevEducation => ({
                                ...prevEducation,
                                ['MastersReason']: 'Reason required.'
                            }));
                            valid = false;
                        }
                        if(!education.MastersCollege){
                            setEducationError(prevEducation => ({
                                ...prevEducation,
                                ['MastersCollege']: 'College Name is required.'
                            }));
                            valid = false;
                        }
                    }
                }
                if(education.completedCoursePhd){
                    if(education.completedCoursePhd === 'Yes'){
                        if(!education.PhdCompleteDate){
                            setEducationError(prevEducation => ({
                                ...prevEducation,
                                ['PhdCompleteDate']: 'Completion date is required.'
                            }));
                            valid = false;
                        }
                        if(!education.PhdCollege){
                            setEducationError(prevEducation => ({
                                ...prevEducation,
                                ['PhdCollege']: 'College Name is required.'
                            }));
                            valid = false;
                        }

                    }else{
                        if(!education.PhdReason){
                            setEducationError(prevEducation => ({
                                ...prevEducation,
                                ['PhdReason']: 'Reason required.'
                            }));
                            valid = false;
                        }
                        if(!education.PhdCollege){
                            setEducationError(prevEducation => ({
                                ...prevEducation,
                                ['PhdCollege']: 'College Name is required.'
                            }));
                            valid = false;
                        }
                    }
                }
            }

        }else{
            setEducationError(prevEducation => ({
                ...prevEducation,
                ['studyCollage']: 'You must select one of these options'
            }));
            valid = false;
        }
        

        return valid;
        

        
    }
    const handleEducation = async () => {
        
        if(!validateEducation()){
            return false;
        }

        
        try{
            setIsLoading(true)
            const formData = new FormData();
            formData.append('eight_status',education.EightthStandYes)
            formData.append('tenth_status',education.tenthStandYes)
            formData.append('twelve_status',education.twelvethStand)
            formData.append('college_status',education.studyCollage)
            formData.append('college_name',education.NameOfCollege)
            formData.append('degree_status',education.completedCourseBachelors)
            education.BachelorsCompleteDate && formData.append('degree_date',education.BachelorsCompleteDate)
            education.bachelorReason && formData.append('degree_reason',education.bachelorReason)

            education.MastersCollege && formData.append('master_college',education.MastersCollege)
            education.completedCourseMasters && formData.append('master_status',education.completedCourseMasters)
            education.MastersCompleteDate && formData.append('master_date',education.MastersCompleteDate)
            education.MastersReason && formData.append('master_reason',education.MastersReason)

            education.PhdCollege && formData.append('phd_college',education.PhdCollege)
            education.completedCoursePhd && formData.append('phd_status',education.completedCoursePhd)
            education.PhdCompleteDate && formData.append('phd_date',education.PhdCompleteDate)
            education.PhdReason && formData.append('phd_reason',education.PhdReason)

            const storeloginid = await localStorage.getItem('login_id');
            const response = await axios.post(`${baseURL}/handleEducation/${storeloginid}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
                
            });
            //alert(response)
            console.log('hi',response)
            if(response.data.status == 1){
                setMessage(response.data.message);
                toast.success('Education Details Updated')
                setIsSuccess(true);
                setShowSuccessAlert(true);
                setShowFiledAlert(false);
                setTimeout(() => {
                    
                    setIsLoading(false)
                    setIsEducationPopupVisible(false);
                    document.querySelector('.shimmer').style.display = 'none';
                    if (bodyRef.current) bodyRef.current.style.overflow = 'inherit';
                    //setValue((prevValue) => prevValue + 1)
                    //navigate('/jobs')
                },2000)
            }
            
        }catch(err){
            console.log(err)
            
        }
    }
    const educationType = {
                            didntAttend : 'I did not attend college/university',
                            NotComplete : 'I went to college/university, but did not complete it',
                            Complete : 'I graduated from college/university'
                        }
    //fetch_education
    useEffect(()=>{
        const fetchEducation = async () => {
            const storeloginid = await localStorage.getItem('login_id');
            try {
                const response = await axios.get(`${baseURL}/Education/${storeloginid}`);
                console.log('education',response)
                if (response.data.status == 1) {
                    const data = response.data.data;
                    setEducation({
                        EightthStandYes:data.eight_class_status || '',
                        tenthStandYes:data.tenth_class_status || '',
                        twelvethStand:data.twelth_class_status || '',
                        studyCollage:data.collage_status || '',

                        completedCourseBachelors:data.degree_completed_status || '',
                        BachelorsCompleteDate:data.degree_completed_date || '',
                        bachelorReason:data.degree_non_completed_reason || '',
                        NameOfCollege:data.degree_collage_name || '',

                        completedCourseMasters:data.master_completed_status || '',
                        MastersCompleteDate:data.master_completed_date || '',
                        MastersReason:data.master_non_completed_reason || '',
                        MastersCollege:data.master_collage_name || '',

                        completedCoursePhd:data.phd_completed_status || '',
                        PhdCompleteDate:data.phd_completed_date || '',
                        PhdReason:data.phd_non_completed_reason || '',
                        PhdCollege:data.phd_collage_name || '',
                    })
                } else {
                   
                }
            } catch (err) {
                console.log(err);
                
            }
        };
    
        fetchEducation();
    },[])



    const handleDownload = (resume1) => {
        // Assuming resume is the filename, not the full URL
        const resume = resume1.substring(resume1.lastIndexOf('/') + 1);
        const fileUrl = `${baseURL}/downloadresume`;
    
        axios.post(fileUrl, { resume: resume }, { responseType: 'blob' })  // Send just the filename
            .then(response => {
                //console.log(response.data)
                // Create a URL for the file blob
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', resume);  // Specify a name for the downloaded file
                document.body.appendChild(link);
                link.click();
                link.remove();  // Clean up
            })
            .catch(error => {
                console.error('Error downloading file:', error);
            });
    };
    
  return (
    <>

        {IsregisterPopupVisible && (
            <div className="profileFormPopup">
                <div className="profileFormPopupHead">
                    <h2 className="subTitle">Register Details</h2>
                </div>
                <div className="profileFormPopupBody">
                     <form action="" >
                        <div className="formGroup">
                            <label htmlFor="">Name</label>
                            <input type="text" name="candidate_name" onKeyUp={handleChangev1} onChange={handleChangev1} value={formDataprofile.candidate_name}/>
                            {errors.candidate_name && <p className="error">{errors.candidate_name}</p>}
                        </div>
                        <div className="formGroup">
                            <label htmlFor="">Email</label>
                            <input type="email" name="email_id" disabled onKeyUp={handleChangev1} onChange={handleChangev1} value={formDataprofile.email_id}/>
                            {errors.email_id && <p className="error">{errors.email_id}</p>}
                        </div>
                        <div className="formGroup">
                            <label htmlFor="">Phone</label>
                            <input type="number" name="phone_number" onKeyUp={handleChangev1} onChange={handleChangev1} value={formDataprofile.phone_number}/>
                            {errors.phone_number && <p className="error">{errors.phone_number}</p>}
                        </div>
                    </form> 
                </div>
                <div className="profileFormPopupFooter">
                    <div className="formBtnArea">
                        <div className="closeProfileFormPopup" onClick={handleProfileCancelClick}>Cancel</div>
                        <button className="submitProfileFormPopup"  onClick={handleregisterSubmit} disabled={isLoading}>{isLoading ? <Loader /> : 'Submit'}</button>
                    </div>
                </div>
            </div>
        )}

        {isPopupVisible && (
            <div className="deleteConfirmPopup">
                <div className="deleteConfirmPopupMain">
                    <h2>
                        {itemType === 'resume' 
                            ? 'Are you sure you want to delete this resume?' 
                            : 'Are you sure you want to delete this education detail?'}
                    </h2>
                    <div className="deleteConfirmPopupFooter">
                        <div className="closeDeleteConfirmPopup" onClick={handleCancelClick}>Cancel</div>
                        <div className="confirmDeleteConfirmPopup" onClick={handleConfirmDeleteClick}>Delete</div>
                    </div>
                </div>
            </div>
        )}

        {/* delete popup resume  */}
        {isDeleteResumePopupVisible && (
            <div className="deleteConfirmPopup">
                <div className="deleteConfirmPopupMain">
                    <h2>Are you sure you want to delete this resume?</h2>
                    <div className="deleteConfirmPopupFooter">
                        <div className="closeDeleteConfirmPopup" onClick={handleResumeDeleteCancelClick}>Cancel</div>
                        <div className="confirmDeleteConfirmPopup" onClick={handleResumeDeleteConfirmDeleteClick}>Delete</div>
                    </div>
                </div>
            </div>
        )}

        {isProfileImagePopupVisible && (
            <div className="profileFormPopup">
                <div className="profileFormPopupHead">
                    <h2 className="subTitle">Profile Image</h2>
                </div>
                <div className="profileFormPopupBody">
                    <form action="">
                    <ProfileImage onCropCompleteLast={handleCroppedImage} initialImageSrc={globalState}/>
                    {errors.cropedimagv && <p className="error">{errors.cropedimagv}</p>}
                    </form>
                </div>
                <div className="profileFormPopupFooter">
                    <div className="formBtnArea">
                        <div className="closeProfileFormPopup" onClick={handleProfileCancelClick}>Cancel</div>
                        <button className="submitProfileFormPopup" onClick={profilePhoto}>{isLoading ? <Loader /> : 'Submit'}</button>
                    </div>
                </div>
            </div>
        )}

        {isProfilePopupVisible && (
            <div className="profileFormPopup">
                <div className="profileFormPopupHead">
                    <h2 className="subTitle">Profile Details</h2>
                </div>
                <div className="profileFormPopupBody">
                    <form action="" >
                        <div className="formGroup col-1">
                            <label htmlFor="">Profile Image</label>
                            <ProfileImage onCropCompleteLast={handleCroppedImage} initialImageSrc={globalState}/>
                            {errors.cropedimagv && <p className="error">{errors.cropedimagv}</p>}
                        </div>
                        <div className="formGroup">
                            <label htmlFor="">First Name</label>
                            <input type="text" name="firstname" onKeyUp={handleChangev1} onChange={handleChangev1} value={formData.firstname}/>
                            {errors.firstname && <p className="error">{errors.firstname}</p>}
                        </div>
                        <div className="formGroup">
                            <label htmlFor="">Last Name</label>
                            <input type="text" name="lastname" onKeyUp={handleChangev1} onChange={handleChangev1} value={formData.lastname}/>
                            {errors.lastname && <p className="error">{errors.lastname}</p>}
                        </div>
                        <div className="formGroup">
                            <label htmlFor="">Phone Number</label>
                            <input type="number" name="phonenumber" onKeyUp={handleChangev1} onChange={handleChangev1} value={formData.phonenumber}/>
                            {errors.phonenumber && <p className="error">{errors.phonenumber}</p>}
                        </div>
                        <div className="formGroup">
                            <label htmlFor="">Email</label>
                            <input type="email" name="emailid" onKeyUp={handleChangev1} onChange={handleChangev1} value={formData.emailid}/>
                            {errors.emailid && <p className="error">{errors.emailid}</p>}
                        </div>
                        <div className="formGroup">
                            <label htmlFor="">Work Status</label>
                            <div className="formRadioGroup">
                                <div className="formRadioGroupBox">
                                    <input type="radio" id='fresher' name="workStatus" checked={workStatus === 'fresher'} 
                                        onChange={handleWorkStatusChange} />
                                    <label htmlFor="fresher">
                                        <div className="radioIcon"></div>
                                        <div className="radioText">Fresher</div>
                                    </label>
                                </div>
                                <div className="formRadioGroupBox">
                                    <input type="radio" id='experience' name="workStatus" checked={workStatus === 'experience'} 
                                        onChange={handleWorkStatusChange} />
                                    <label htmlFor="experience">
                                        <div className="radioIcon"></div>
                                        <div className="radioText">Experience</div>
                                    </label>
                                </div>
                            </div>
                        </div>
                        {workStatus === 'experience' && (
                            <div className="formGroup col-1 experienceArea">
                                {/* <label htmlFor="">Total experience</label>
                                <div className="formGroupDorpdownList">
                                    <div className="formGroupDorpdownListBox">
                                        <Select
                                            options={year}
                                            placeholder="Number of Year"
                                            isSearchable={true}
                                            className="customSelect"
                                            isClearable={isClearable}
                                            styles={customStyles}
                                            name="totalexperince"
                                            
                                            value={year.find(p=>p.value === formData.totalexperince)}
                                            onChange={handletotalexperiance}
                                        />
                                        {errors.totalexperince && <p className="error">{errors.totalexperince}</p>}
                                    </div>
                                    
                                    <div className="formGroupDorpdownListBox">
                                        <Select
                                            options={month}
                                            placeholder="Number of Month"
                                            isSearchable={true}
                                            className="customSelect"
                                            isClearable={isClearable}
                                            styles={customStyles}
                                            name="numbermonth"
                                            
                                            value={month.find(p=>p.value === formData.numbermonth)}
                                            onChange={handlenumbermonth}
                                        />
                                        {errors.numbermonth && <p className="error">{errors.numbermonth}</p>}
                                    </div>
                                    
                                </div> */}
                                <div className="formTable">
                                    <div className="formTableHead">
                                        <div className="formTableHeadBox">Start Date</div>
                                        <div className="formTableHeadBox">End Date</div>
                                        <div className="formTableHeadBox">Name of Organization / Company</div>
                                        <div className="formTableHeadBox">Position</div>
                                        <div className="formTableHeadBox"></div>
                                    </div>
                                    <div className="formTableBody">

                                        {/* Render Rows Dynamically */}
                                        {rows.map((row, index) => (
                                                <div key={index} className="formTableBodyRow">
                                                    <div className="formTableBodyBox">
                                                        <label htmlFor="" className="formTableLabel">Start Date</label>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <DatePicker
                                                                views={['year', 'month']}
                                                                value={row.startDate ? dayjs(row.startDate) : null}
                                                                onChange={(newValue) => {
                                                                    if (newValue && newValue.isValid()) {
                                                                        const formattedValue = newValue.format('MMM YYYY');
                                                                        updateRow(index, 'startDate', formattedValue);
                                                                    }
                                                                }}
                                                                renderInput={(params) => <input {...params} />}
                                                                format="MMM YYYY"
                                                            />
                                                        </LocalizationProvider>
                                                        {validationErrors[`startDate_${index}`] && (
                                                            <div className="error">{validationErrors[`startDate_${index}`]}</div>
                                                        )}
                                                    </div>

                                                    <div className="formTableBodyBox">
                                                        <label htmlFor="" className="formTableLabel">End Date</label>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <DatePicker
                                                                views={['year', 'month']}
                                                                value={row.endDate ? dayjs(row.endDate) : null}
                                                                onChange={(newValue) => {
                                                                    if (newValue && newValue.isValid()) {
                                                                        const formattedValue = newValue.format('MMM YYYY');
                                                                        updateRow(index, 'endDate', formattedValue);
                                                                    }
                                                                }}
                                                                renderInput={(params) => <input {...params} />}
                                                                format="MMM YYYY"
                                                            />
                                                        </LocalizationProvider>
                                                        {validationErrors[`endDate_${index}`] && (
                                                            <div className="error">{validationErrors[`endDate_${index}`]}</div>
                                                        )}
                                                    </div>

                                                    <div className="formTableBodyBox">
                                                        <label htmlFor="" className="formTableLabel">Name of Organization / Company</label>
                                                        <input
                                                            type="text"
                                                            value={row.companyName}
                                                            onChange={(e) => updateRow(index, 'companyName', e.target.value)}
                                                        />
                                                        {validationErrors[`companyName_${index}`] && (
                                                            <div className="error">{validationErrors[`companyName_${index}`]}</div>
                                                        )}
                                                    </div>

                                                    <div className="formTableBodyBox">
                                                        <label htmlFor="" className="formTableLabel">Position</label>
                                                        <input
                                                            type="text"
                                                            value={row.position}
                                                            onChange={(e) => updateRow(index, 'position', e.target.value)}
                                                        />
                                                        {validationErrors[`position_${index}`] && (
                                                            <div className="error">{validationErrors[`position_${index}`]}</div>
                                                        )}
                                                    </div>

                                                    <div className="formTableBodyBox">
                                                        {index > 0 && (
                                                            <button
                                                                type="button"
                                                                className="deleteformTableBodyRowBtn"
                                                                onClick={() => removeRow(index,row.id)}
                                                            >
                                                                Delete
                                                            </button>
                                                        )}
                                                    </div>
                                                </div>
                                            ))}
                                        <div className="formTableBodyRow">
                                            <div className="formTableBodyBox">
                                                <button type="button" className="addformTableBodyRowBtn" onClick={addRow} style={{marginLeft: 'auto'}}>
                                                    Add More
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {/* <div className="formGroup col-1">
                            <label htmlFor="">Current location</label>
                            <div className="formGroupDorpdownList">
                                <div className="formGroupDorpdownListBox">
                                    <Select
                                        options={locations}
                                        placeholder="Select Country"
                                        isSearchable={true}
                                        className="customSelect"
                                        isClearable={isClearable}
                                        styles={customStyles}
                                        name="counrtryselect"
                                        onChange={handlecounrtyselect}
                                        value={locations.find(p=>p.value === formData.counrtryselect)}
                                    />
                                    {errors.counrtryselect && <p className="error">{errors.counrtryselect}</p>}
                                </div>
                                
                                <div className="formGroupDorpdownListBox">
                                    <Select
                                        options={place}
                                        placeholder="Select Place"
                                        isSearchable={true}
                                        className="customSelect"
                                        isClearable={isClearable}
                                        styles={customStyles}
                                        name="placeselect"
                                        onChange={handleplaceselect}
                                        value={place.find(p=>p.value === formData.placeselect)}
                                    />
                                    {errors.placeselect && <p className="error">{errors.placeselect}</p>}
                                </div>
                                
                            </div>
                        </div> */}
                        <div className="formGroup">
                            <label htmlFor="">Country</label>
                            <input type="text" name="counrtryselect" onKeyUp={handleChangev1} onChange={handleChangev1} value={formData.counrtryselect}/>
                            {errors.counrtryselect && <p className="error">{errors.counrtryselect}</p>}
                        </div>
                        <div className="formGroup">
                            <label htmlFor="">Place</label>
                            <input type="text" name="placeselect" onKeyUp={handleChangev1} onChange={handleChangev1} value={formData.placeselect}/>
                            {errors.placeselect && <p className="error">{errors.placeselect}</p>}
                        </div>
                        <div className="formGroup col-1">
                            <label htmlFor="">Profile Summary</label>
                            <textarea name="profilesummary" onKeyUp={handleChangev1} onChange={handleChangev1} value={formData.profilesummary}></textarea>
                            {errors.profilesummary && <p className="error">{errors.profilesummary}</p>}
                        </div>
                    </form> 
                </div>
                <div className="profileFormPopupFooter">
                    <div className="formBtnArea">
                        <div className="closeProfileFormPopup" onClick={handleProfileCancelClick}>Cancel</div>
                        <button className="submitProfileFormPopup"  onClick={handleSubmit} disabled={isLoading}>{isLoading ? <Loader /> : 'Submit'}</button>
                    </div>
                </div>
            </div>
        )}

        {isResumePopupVisible && (
            <div className="profileFormPopup">
                <div className="profileFormPopupHead">
                    <h2 className="subTitle">Upload Resume</h2>
                </div>
                <div className="profileFormPopupBody">
                    <form action="">
                    <UploadResume onHandleImageUpload={handleImageUpload} existingResume={pdfFile} />
                    {pdfError && <p className="error">{pdfError}</p>}
                    </form>
                </div>
                <div className="profileFormPopupFooter">
                    <div className="formBtnArea">
                        <div className="closeProfileFormPopup" onClick={handleProfileCancelClick}>Cancel</div>
                        <button className="submitProfileFormPopup" onClick={handleResume}>{isLoading ? <Loader /> : 'Submit'}</button>
                    </div>
                </div>
            </div>
        )}

        {isEducationPopupVisible && (
            <div className="profileFormPopup">
                <div className="profileFormPopupHead">
                    <h2 className="subTitle">Education Details</h2>
                </div>
                <div className="profileFormPopupBody">
                    <form action="">
                        <EducationForm education={education} setEducation={setEducation} educationError={educationError} setEducationError={setEducationError}/>
                    </form>
                </div>
                <div className="profileFormPopupFooter">
                    <div className="formBtnArea">
                        <div className="closeProfileFormPopup" onClick={handleProfileCancelClick}>Cancel</div>
                        <button className="submitProfileFormPopup" onClick={handleEducation} disabled={isLoading}>{isLoading ? <Loader /> : <>Submit </>}</button>
                    </div>
                </div>
            </div>
        )}

        {isPersonalPopupVisible && (
            <div className="profileFormPopup">
                <div className="profileFormPopupHead">
                    <h2 className="subTitle">Personal Details</h2>
                </div>
                <div className="profileFormPopupBody">
                    <form action="">
                    <PersonalDetailsForm formDataperson={formDataperson} setFormDataperson={setFormDataperson} personalErrors={personalErrors} setPersonalErrors={setPersonalErrors}/>
                    </form>
                </div>
                <div className="profileFormPopupFooter">
                    <div className="formBtnArea">
                        <div className="closeProfileFormPopup" onClick={handleProfileCancelClick}>Cancel</div>
                        <button className="submitProfileFormPopup"  onClick={handlepersondetails} disabled={isLoading}> {isLoading ? <Loader /> : 'Submit'}</button>
                    </div>
                </div>
            </div>
        )}

        <section id="profileSection">
            <div className="container">
                <div className="profileSectionMain">
                    <div className="profileSectionCol1">
                        <div className="profileSectionCol1Head">
                            <h2 className="subTitle">Quick links</h2>
                        </div>
                        <div className="profileSectionCol1Body">
                            <Tabs
                                orientation={isMobile ? "horizontal" : "vertical"}
                                variant="scrollable"
                                value={value}
                                onChange={handleChange}
                                aria-label="Profile tabs"
                            >
                                <Tab label="Profile" {...a11yProps(0)} />
                                <Tab label="Resume" {...a11yProps(1)} />
                                <Tab label="Education" {...a11yProps(2)} />
                                <Tab label="Personal Details" {...a11yProps(3)} />
                            </Tabs>
                        </div>
                    </div>
                    <div className="profileSectionCol2">
                        <TabPanel value={value} index={0}>
                            {/* <div className="profileSectionListBox">
                                <div className="profileSectionListBoxDetails">
                                    
                                    <div className="profileSectionListBoxDetailsCol2">
                                        <h2 className="title">{formDataprofile.candidate_name|| 'No Data' }</h2>
                                        <ul>
                                            <li>
                                                <div className="icon">
                                                    <BiPhone />
                                                </div>
                                                <p>{formDataprofile.phone_number || 'No Data'}</p>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <MdOutlineAlternateEmail />
                                                </div>
                                                <p>{formDataprofile.email_id || 'No Data'}</p>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="profileSectionListBoxDetailsCol3">
                                        <div className="profileSectionEditBtn" onClick={editregisterClick}><MdOutlineEdit /> Edit</div>
                                    </div>
                                    <div className="profileSectionListBoxDetailsCol4">
                                        <p>{formData.profilesummary}</p>
                                    </div>
                                </div>
                            </div> */}

                            <div className="profileSectionListBox">
                                <div className="profileSectionListBoxDetails">
                                    <div className="profileSectionListBoxDetailsCol1">
                                        <div className="profileImage" onClick={profileImageBtnClick}>
                                            <img src={globalState || placeHolderImg} alt="" />
                                            <div className="profileImageEditBtn">
                                                <IoAdd />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="profileSectionListBoxDetailsCol2">
                                        <h2 className="title">{formData.firstname || 'Your Name'} {formData.lastname || ''}</h2>
                                        <ul>
                                            <li>
                                                <div className="icon">
                                                    <BiPhone />
                                                </div>
                                                <p>{formData.phonenumber || 'No Data'}</p>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <MdOutlineAlternateEmail />
                                                </div>
                                                <p>{formData.emailid || 'No Data'}</p>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <HiOutlineLocationMarker />
                                                </div>
                                                <p style={{'text-transform': 'capitalize'}}>{formData.counrtryselect || 'No Data'}</p>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <TbUserScan />
                                                </div>
                                               
                                                {/* <p>{(formData.work_status === 'fresher' && formData.work_status )? 'Fresher' : 'Experienced'}</p> */}
                                                <p style={{'text-transform': 'capitalize'}}>{formData.work_status || 'No Data'}</p>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="profileSectionListBoxDetailsCol3">
                                        <div className="profileSectionEditBtn" onClick={editProfileClick}><MdOutlineEdit /> Edit</div>
                                    </div>
                                    {formData.work_status === 'experience' &&(
                                        <div className="profileSectionListBoxDetailsCol4">
                                            <p>{formData.profilesummary}</p>
                                            <div className="workHistory">
                                                <h2 className="subTitle">Work History</h2>
                                                <div className="workHistoryBody">
                                                    {formData.work_status === 'experience' ? rows.map((row, index) => (
                                                        <div key={index} className="workHistoryBodyRow">
                                                            <div className="workHistoryBodyBox">
                                                                <label htmlFor="" className="workHistoryLabel">Start Date</label>
                                                                <h4>{row.startDate}</h4>
                                                            </div>
                                                            <div className="workHistoryBodyBox">
                                                                <label htmlFor="" className="workHistoryLabel">End Date</label>
                                                                <h4>{row.endDate || 'Present'}</h4>
                                                            </div>
                                                            <div className="workHistoryBodyBox">
                                                                <label htmlFor="" className="workHistoryLabel">Name of Organization / Company</label>
                                                                <h4>{row.companyName}</h4>
                                                            </div>
                                                            <div className="workHistoryBodyBox">
                                                                <label htmlFor="" className="workHistoryLabel">Position</label>
                                                                <h4>{row.position}</h4>
                                                            </div>
                                                        </div>
                                                    )) : <> No History</>}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                    }
                                </div>
                            </div>
                            <div className="profileSectionListBox">
                                <div className="profileSectionListBoxHead">
                                    <div className="profileSectionListBoxHeadCol1">
                                        <h2 className="subTitle">Resume</h2>
                                    </div>
                                    <div className="profileSectionListBoxHeadCol2">
                                        <div className="profileSectionEditBtn"  onClick={editResumeClick}><MdOutlineEdit /> Edit</div>
                                    </div>
                                </div>
                                <div className="profileSectionListBoxBody">
                                <div className="reviewBoxBody">
                                    <div className="resumeList">
                                        {/* {console.log(intailFile)} */}
                                        {intailFile ? (intailFile.map((resume, index) => (
                                            <div className="resumeListBox" key={index}>
                                                <div className="resumeListBoxCol1">
                                                    <p>{resume.split('/').pop()}</p>
                                                </div>
                                                <div className="resumeListBoxCol2">
                                                    <Link  className="resumeListbtn downloadResume" onClick={typeof resume === 'string' ? () => handleDownload(resume) : null}>
                                                        <RiDownloadLine />
                                                    </Link>
                                                    {/* <button 
                                                        type="button" 
                                                        className='resumeListbtn deleteResume' 
                                                        onClick={() => handleDeleteClick(resume, 'resume')}>
                                                        <RiDeleteBinLine />
                                                    </button> */}
                                                </div>
                                            </div>
                                        ))): <div className="noData"></div>}
                                    </div>
                                </div>
                                </div>
                            </div>
                            {/* <div className="profileSectionListBox">
                                <div className="profileSectionListBoxHead">
                                    <div className="profileSectionListBoxHeadCol1">
                                        <h2 className="subTitle">Education Details</h2>
                                    </div>
                                    <div className="profileSectionListBoxHeadCol2">
                                        <div className="profileSectionEditBtn" onClick={editEducationClick}><MdOutlineEdit /> Edit</div>
                                    </div>
                                </div>
                                <div className="profileSectionListBoxBody">
                                    <div className="educationList">
                                        {educationList.map((education) => (
                                            <div className="educationListBox" key={education.id}>
                                                <div className="educationListBoxHead">
                                                    <div className="educationListBoxHeadCol1">
                                                        <div className="educationTitle">{education.degree}</div>
                                                    </div>
                                                    <div className="educationListBoxHeadCol2">
                                                        <button 
                                                            type="button" 
                                                            className='resumeListbtn educationDeleteBtn' 
                                                            onClick={() => handleDeleteClick(education, 'education')}>
                                                            <RiDeleteBinLine />
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="educationListBoxBody">
                                                    <div className="formGroup">
                                                        <label htmlFor="">University/Institute</label>
                                                        <h4>{education.university}</h4>
                                                    </div>
                                                    <div className="formGroup">
                                                        <label htmlFor="">Course</label>
                                                        <h4>{education.course}</h4>
                                                    </div>
                                                    <div className="formGroup">
                                                        <label htmlFor="">Specialization</label>
                                                        <h4>{education.specialization}</h4>
                                                    </div>
                                                    <div className="formGroup">
                                                        <label htmlFor="">Course type</label>
                                                        <h4>{education.courseType}</h4>
                                                    </div>
                                                    <div className="formGroup">
                                                        <label htmlFor="">Course duration</label>
                                                        <h4>{education.duration}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div> */}
                            <div className="profileSectionListBox">
                                <div className="profileSectionListBoxHead">
                                    <div className="profileSectionListBoxHeadCol1">
                                        <h2 className="subTitle">Educational Background – Part 1</h2>
                                    </div>
                                    <div className="profileSectionListBoxHeadCol2">
                                        <div className="profileSectionEditBtn" onClick={editEducationClick}><MdOutlineEdit /> Edit</div>
                                    </div>
                                </div>                                        
                                <div className="profileSectionListBoxBody">
                                    <div className="educationList">
                                        {/* {educationList.map((education) => (
                                            <div className="educationListBox" key={education.id}>
                                                <div className="educationListBoxHead">
                                                    <div className="educationListBoxHeadCol1">
                                                        <div className="educationTitle">{education.degree}</div>
                                                    </div>
                                                    <div className="educationListBoxHeadCol2">
                                                        <button 
                                                            type="button" 
                                                            className='resumeListbtn educationDeleteBtn' 
                                                            onClick={() => handleDeleteClick(education, 'education')}>
                                                            <RiDeleteBinLine />
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="educationListBoxBody">
                                                    <div className="formGroup">
                                                        <label htmlFor="">University/Institute</label>
                                                        <h4>{education.university}</h4>
                                                    </div>
                                                    <div className="formGroup">
                                                        <label htmlFor="">Course</label>
                                                        <h4>{education.course}</h4>
                                                    </div>
                                                    <div className="formGroup">
                                                        <label htmlFor="">Specialization</label>
                                                        <h4>{education.specialization}</h4>
                                                    </div>
                                                    <div className="formGroup">
                                                        <label htmlFor="">Course type</label>
                                                        <h4>{education.courseType}</h4>
                                                    </div>
                                                    <div className="formGroup">
                                                        <label htmlFor="">Course duration</label>
                                                        <h4>{education.duration}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        ))} */}
                                        <div className="educationListBox">
                                            <div className="educationListBoxHead">
                                                <div className="educationListBoxHeadCol1">
                                                    <div className="educationTitle">8th standard</div>
                                                </div>
                                            </div>
                                            <div className="educationListBoxBody">
                                                <div className="formGroup col-3">
                                                    <label htmlFor="">Completed?</label>
                                                    <h4>{education.EightthStandYes || 'No Data'}</h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="educationListBox">
                                            <div className="educationListBoxHead">
                                                <div className="educationListBoxHeadCol1">
                                                    <div className="educationTitle">10th standard</div>
                                                </div>
                                            </div>
                                            <div className="educationListBoxBody">
                                                <div className="formGroup col-3">
                                                    <label htmlFor="">Completed?</label>
                                                    <h4>{education.tenthStandYes || 'No Data'}</h4>
                                                </div>
                                                
                                            </div>
                                        </div>
                                        <div className="educationListBox">
                                            <div className="educationListBoxHead">
                                                <div className="educationListBoxHeadCol1">
                                                    <div className="educationTitle">12th standard</div>
                                                </div>
                                            </div>
                                            <div className="educationListBoxBody">
                                                <div className="formGroup col-3">
                                                    <label htmlFor="">Completed?</label>
                                                    <h4>{education.twelvethStand || 'No Data'}</h4>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="profileSectionListBox">
                                <div className="profileSectionListBoxHead">
                                    <div className="profileSectionListBoxHeadCol1">
                                        <h2 className="subTitle">Educational Background – Part 2</h2>
                                    </div>
                                </div>                                     
                                <div className="profileSectionListBoxBody">
                                    <div className="educationList">
                                        <div className="educationListBox">
                                            <div className="educationListBoxHead">
                                                <div className="educationListBoxHeadCol1">
                                                    <div className="educationTitle">{educationType[education.studyCollage]}</div>
                                                </div>
                                            </div>
                                            {education.studyCollage !== 'didntAttend' &&
                                                <div className="educationListBoxBody">
                                                    <div className="formGroup col-3">
                                                        <label htmlFor="">Type</label>
                                                        <h4>Bachelors Degree</h4>
                                                    </div>
                                                    <div className="formGroup col-3">
                                                        <label htmlFor="">Name of College/ University</label>
                                                        <h4>{education.NameOfCollege || 'No Data'}</h4>
                                                    </div>
                                                    <div className="formGroup col-3">
                                                        <label htmlFor="">Completed?</label>
                                                        <h4>{education.completedCourseBachelors || 'No Data'}</h4>
                                                    </div>
                                                    {education.completedCourseBachelors === 'Yes' &&
                                                        <div className="formGroup col-3">
                                                            <label htmlFor="">Completion Date</label>
                                                            <h4>{education.BachelorsCompleteDate}</h4>
                                                        </div>
                                                    }
                                                    {education.completedCourseBachelors === 'No' &&
                                                        <div className="formGroup col-3">
                                                            <label htmlFor="">Reason</label>
                                                            <h4>{education.bachelorReason}</h4>
                                                        </div>
                                                    }
                                                    <div className="line"></div>
                                                    {education.completedCourseMasters  && (
                                                        <>
                                                            <div className="formGroup col-3">
                                                                <label htmlFor="">Type</label>
                                                                <h4>Masters Degree</h4>
                                                            </div>
                                                            <div className="formGroup col-3">
                                                                <label htmlFor="">Name of College/ University</label>
                                                                <h4>{education.MastersCollege || 'No Data'}</h4>
                                                            </div>
                                                            {education.completedCourseMasters  &&
                                                                <div className="formGroup col-3">
                                                                    <label htmlFor="">Completed?</label>
                                                                    <h4>{education.completedCourseMasters}</h4>
                                                                </div>
                                                            }
                                                            {education.completedCourseMasters === 'Yes' &&
                                                                <div className="formGroup col-3">
                                                                    <label htmlFor="">Completion Date</label>
                                                                    <h4>{education.MastersCompleteDate}</h4>
                                                                </div>
                                                            }
                                                            {education.completedCourseMasters === 'No' &&                      
                                                                <div className="formGroup col-3">
                                                                    <label htmlFor="">Reason</label>
                                                                    <h4>{education.MastersReason}</h4>
                                                                </div>
                                                            }
                                                        </>
                                                    )}
                                                    
                                                    {education.completedCoursePhd  && (
                                                        <>
                                                        <div className="line"></div>
                                                        <div className="formGroup col-3">
                                                            <label htmlFor="">Type</label>
                                                            <h4>PhD Doctorate</h4>
                                                        </div>
                                                        <div className="formGroup col-3">
                                                            <label htmlFor="">Name of College/ University</label>
                                                            <h4>{education.PhdCollege}</h4>
                                                        </div>
                                                        <div className="formGroup col-3">
                                                            <label htmlFor="">Completed?</label>
                                                            <h4>{education.completedCoursePhd}</h4>
                                                        </div>
                                                        {education.completedCoursePhd === 'Yes' &&
                                                            <div className="formGroup col-3">
                                                                <label htmlFor="">Completion Date</label>
                                                                <h4>{education.PhdCompleteDate}</h4>
                                                            </div>
                                                        }
                                                        {education.completedCoursePhd === 'No' &&
                                                            <div className="formGroup col-3">
                                                                <label htmlFor="">Reason</label>
                                                                <h4>{education.PhdReason}</h4>
                                                            </div>
                                                        }
                                                        </>
                                                    )}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>  
                            <div className="profileSectionListBox">
                                <div className="profileSectionListBoxHead">
                                    <div className="profileSectionListBoxHeadCol1">
                                        <h2 className="subTitle">Personal Details</h2>
                                    </div>
                                    <div className="profileSectionListBoxHeadCol2">
                                        <div className="profileSectionEditBtn" onClick={editPersonalClick}><MdOutlineEdit /> Edit</div>
                                    </div>
                                </div>
                                <div className="profileSectionListBoxBody">
                                    <div className="presonalDetails">
                                        <div className="formGroup">
                                            <label htmlFor="">Gender</label>
                                            <h4>{formDataperson.gender_val || 'No Data'}</h4>
                                        </div>
                                        <div className="formGroup">
                                            <label htmlFor="">Marital status</label>
                                            <h4>{formDataperson.marital_status || 'No Data'}</h4>
                                        </div>
                                        <div className="formGroup">
                                            <label htmlFor="">Date of birth</label>
                                            <h4>{formDataperson.day_val || ''} {formDataperson.month_val} {formDataperson.year_val}</h4>
                                        </div>
                                        <div className="formGroup">
                                            <label htmlFor="">Permanent address</label>
                                            <h4>{formDataperson.permenent_adress || 'No Data'}</h4>
                                        </div>
                                        <div className="formGroup">
                                            <label htmlFor="">Home Town</label>
                                            <h4>{formDataperson.home_town || 'No Data'}</h4>
                                        </div>
                                        <div className="formGroup">
                                            <label htmlFor="">Pincode</label>
                                            <h4>{formDataperson.pincode_val || 'No Data'}</h4>
                                        </div>
                                        <div className="formGroup col-1">
                                            <label htmlFor="">Language Proficiency</label>
                                            <div className="languageTable">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>Languages</th>
                                                            <th>Proficiency</th>
                                                            <th>Read</th>
                                                            <th>Write</th>
                                                            <th>Speak</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {language && language.length > 0 ? (
                                                            language.map((lang) => (
                                                                <tr key={lang.language_val}>
                                                                    <td>{lang.language_val}</td>
                                                                    <td>{lang.proficiancy_val}</td>
                                                                    <td>{lang.read_status == 1 ? <MdOutlineCheckCircle /> : '-'}</td>
                                                                    <td>{lang.write_status == 1 ? <MdOutlineCheckCircle /> : '-'}</td>
                                                                    <td>{lang.speak_status == 1 ? <MdOutlineCheckCircle /> : '-'}</td>
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <tr>
                                                                <td colSpan="5">No languages listed</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <div className="profileSectionListBox">
                                <div className="profileSectionListBoxHead">
                                    <div className="profileSectionListBoxHeadCol1">
                                        <h2 className="subTitle">Resume</h2>
                                    </div>
                                    <div className="profileSectionListBoxHeadCol2">
                                        <div className="profileSectionEditBtn" onClick={editResumeClick}><MdOutlineEdit /> Edit</div>
                                    </div>
                                </div>
                                <div className="profileSectionListBoxBody">
                                    <div className="resumeList">
                                        {intailFile ? (intailFile.map((resume, index) => (
                                            <div className="resumeListBox" key={index}>
                                                <div className="resumeListBoxCol1">
                                                    <p>{resume.split('/').pop()}</p>
                                                </div>
                                                <div className="resumeListBoxCol2">
                                                    <Link to={resume} className="resumeListbtn downloadResume" download>
                                                        <RiDownloadLine />
                                                    </Link>
                                                    {/* <button 
                                                        type="button" 
                                                        className='resumeListbtn deleteResume' 
                                                        onClick={() => handleDeleteClick(resume, 'resume')}>
                                                        <RiDeleteBinLine />
                                                    </button> */}
                                                </div>
                                            </div>
                                        ))): <div className="noData"></div>}
                                    </div>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                        <div className="profileSectionListBox">
                            <div className="profileSectionListBoxHead">
                                <div className="profileSectionListBoxHeadCol1">
                                    <h2 className="subTitle">Educational Background – Part 1</h2>
                                </div>
                                <div className="profileSectionListBoxHeadCol2">
                                    <div className="profileSectionEditBtn" onClick={editEducationClick}><MdOutlineEdit /> Edit</div>
                                </div>
                            </div>                                        
                            <div className="profileSectionListBoxBody">
                                <div className="educationList">
                                    {/* {educationList.map((education) => (
                                        <div className="educationListBox" key={education.id}>
                                            <div className="educationListBoxHead">
                                                <div className="educationListBoxHeadCol1">
                                                    <div className="educationTitle">{education.degree}</div>
                                                </div>
                                                <div className="educationListBoxHeadCol2">
                                                    <button 
                                                        type="button" 
                                                        className='resumeListbtn educationDeleteBtn' 
                                                        onClick={() => handleDeleteClick(education, 'education')}>
                                                        <RiDeleteBinLine />
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="educationListBoxBody">
                                                <div className="formGroup">
                                                    <label htmlFor="">University/Institute</label>
                                                    <h4>{education.university}</h4>
                                                </div>
                                                <div className="formGroup">
                                                    <label htmlFor="">Course</label>
                                                    <h4>{education.course}</h4>
                                                </div>
                                                <div className="formGroup">
                                                    <label htmlFor="">Specialization</label>
                                                    <h4>{education.specialization}</h4>
                                                </div>
                                                <div className="formGroup">
                                                    <label htmlFor="">Course type</label>
                                                    <h4>{education.courseType}</h4>
                                                </div>
                                                <div className="formGroup">
                                                    <label htmlFor="">Course duration</label>
                                                    <h4>{education.duration}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    ))} */}
                                    <div className="educationListBox">
                                        <div className="educationListBoxHead">
                                            <div className="educationListBoxHeadCol1">
                                                <div className="educationTitle">8th standard</div>
                                            </div>
                                        </div>
                                        <div className="educationListBoxBody">
                                            <div className="formGroup col-3">
                                                <label htmlFor="">Completed?</label>
                                                <h4>{education.EightthStandYes || 'No Data'}</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="educationListBox">
                                        <div className="educationListBoxHead">
                                            <div className="educationListBoxHeadCol1">
                                                <div className="educationTitle">10th standard</div>
                                            </div>
                                        </div>
                                        <div className="educationListBoxBody">
                                            <div className="formGroup col-3">
                                                <label htmlFor="">Completed?</label>
                                                <h4>{education.tenthStandYes || 'No Data'}</h4>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div className="educationListBox">
                                        <div className="educationListBoxHead">
                                            <div className="educationListBoxHeadCol1">
                                                <div className="educationTitle">12th standard</div>
                                            </div>
                                        </div>
                                        <div className="educationListBoxBody">
                                            <div className="formGroup col-3">
                                                <label htmlFor="">Completed?</label>
                                                <h4>{education.twelvethStand || 'No Data'}</h4>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="profileSectionListBox">
                            <div className="profileSectionListBoxHead">
                                <div className="profileSectionListBoxHeadCol1">
                                    <h2 className="subTitle">Educational Background – Part 2</h2>
                                </div>
                            </div>                                     
                            <div className="profileSectionListBoxBody">
                                <div className="educationList">
                                    <div className="educationListBox">
                                        <div className="educationListBoxHead">
                                            <div className="educationListBoxHeadCol1">
                                                <div className="educationTitle">{educationType[education.studyCollage]}</div>
                                            </div>
                                        </div>
                                        {education.studyCollage !== 'didntAttend' &&
                                            <div className="educationListBoxBody">
                                                <div className="formGroup col-3">
                                                    <label htmlFor="">Type</label>
                                                    <h4>Bachelors Degree</h4>
                                                </div>
                                                <div className="formGroup col-3">
                                                    <label htmlFor="">Name of College/ University</label>
                                                    <h4>{education.NameOfCollege || 'No Data'}</h4>
                                                </div>
                                                <div className="formGroup col-3">
                                                    <label htmlFor="">Completed?</label>
                                                    <h4>{education.completedCourseBachelors || 'No Data'}</h4>
                                                </div>
                                                {education.completedCourseBachelors === 'Yes' &&
                                                    <div className="formGroup col-3">
                                                        <label htmlFor="">Completion Date</label>
                                                        <h4>{education.BachelorsCompleteDate}</h4>
                                                    </div>
                                                }
                                                {education.completedCourseBachelors === 'No' &&
                                                    <div className="formGroup col-3">
                                                        <label htmlFor="">Reason</label>
                                                        <h4>{education.bachelorReason}</h4>
                                                    </div>
                                                }
                                                <div className="line"></div>
                                                {education.completedCourseMasters  && (
                                                    <>
                                                        <div className="formGroup col-3">
                                                            <label htmlFor="">Type</label>
                                                            <h4>Masters Degree</h4>
                                                        </div>
                                                        <div className="formGroup col-3">
                                                            <label htmlFor="">Name of College/ University</label>
                                                            <h4>{education.MastersCollege}</h4>
                                                        </div>
                                                        {education.completedCourseMasters  &&
                                                            <div className="formGroup col-3">
                                                                <label htmlFor="">Completed?</label>
                                                                <h4>{education.completedCourseMasters}</h4>
                                                            </div>
                                                        }
                                                        {education.completedCourseMasters === 'Yes' &&
                                                            <div className="formGroup col-3">
                                                                <label htmlFor="">Completion Date</label>
                                                                <h4>{education.MastersCompleteDate}</h4>
                                                            </div>
                                                        }
                                                        {education.completedCourseMasters === 'No' &&                      
                                                            <div className="formGroup col-3">
                                                                <label htmlFor="">Reason</label>
                                                                <h4>Some technical issue</h4>
                                                            </div>
                                                        }
                                                    </>
                                                )}
                                                <div className="line"></div>
                                                {education.completedCoursePhd  && (
                                                    <>
                                                    <div className="formGroup col-3">
                                                        <label htmlFor="">Type</label>
                                                        <h4>PhD Doctorate</h4>
                                                    </div>
                                                    <div className="formGroup col-3">
                                                        <label htmlFor="">Name of College/ University</label>
                                                        <h4>{education.PhdCollege}</h4>
                                                    </div>
                                                    <div className="formGroup col-3">
                                                        <label htmlFor="">Completed?</label>
                                                        <h4>{education.completedCoursePhd}</h4>
                                                    </div>
                                                    {education.completedCoursePhd === 'Yes' &&
                                                        <div className="formGroup col-3">
                                                            <label htmlFor="">Completion Date</label>
                                                            <h4>{education.PhdCompleteDate}</h4>
                                                        </div>
                                                    }
                                                    {education.completedCoursePhd === 'No' &&
                                                        <div className="formGroup col-3">
                                                            <label htmlFor="">Reason</label>
                                                            <h4>{education.PhdReason}</h4>
                                                        </div>
                                                    }
                                                    </>
                                                )}
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>  
                        </TabPanel>
                        <TabPanel value={value} index={3}>
                        <div className="profileSectionListBox">
                                <div className="profileSectionListBoxHead">
                                    <div className="profileSectionListBoxHeadCol1">
                                        <h2 className="subTitle">Personal Details</h2>
                                    </div>
                                    <div className="profileSectionListBoxHeadCol2">
                                        <div className="profileSectionEditBtn" onClick={editPersonalClick}><MdOutlineEdit /> Edit</div>
                                    </div>
                                </div>
                                <div className="profileSectionListBoxBody">
                                    <div className="presonalDetails">
                                        <div className="formGroup">
                                            <label htmlFor="">Gender</label>
                                            <h4>{formDataperson.gender_val || 'No Data'}</h4>
                                        </div>
                                        <div className="formGroup">
                                            <label htmlFor="">Marital status</label>
                                            <h4>{formDataperson.marital_status || 'No Data'}</h4>
                                        </div>
                                        <div className="formGroup">
                                            <label htmlFor="">Date of birth</label>
                                            <h4>{formDataperson.day_val || ''} {formDataperson.month_val} {formDataperson.year_val}</h4>
                                        </div>
                                        <div className="formGroup">
                                            <label htmlFor="">Permanent address</label>
                                            <h4>{formDataperson.permenent_adress || 'No Data'}</h4>
                                        </div>
                                        <div className="formGroup">
                                            <label htmlFor="">Home Town</label>
                                            <h4>{formDataperson.home_town || 'No Data'}</h4>
                                        </div>
                                        <div className="formGroup">
                                            <label htmlFor="">Pincode</label>
                                            <h4>{formDataperson.pincode_val || 'No Data'}</h4>
                                        </div>
                                        <div className="formGroup col-1">
                                            <label htmlFor="">Language Proficiency</label>
                                            <div className="languageTable">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>Languages</th>
                                                            <th>Proficiency</th>
                                                            <th>Read</th>
                                                            <th>Write</th>
                                                            <th>Speak</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {language && language.length > 0 ? (
                                                            language.map((lang) => (
                                                                <tr key={lang.language_val}>
                                                                    <td>{lang.language_val}</td>
                                                                    <td>{lang.proficiancy_val}</td>
                                                                    <td>{lang.read_status == 1 ? <MdOutlineCheckCircle /> : '-'}</td>
                                                                    <td>{lang.write_status == 1 ? <MdOutlineCheckCircle /> : '-'}</td>
                                                                    <td>{lang.speak_status == 1 ? <MdOutlineCheckCircle /> : '-'}</td>
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <tr>
                                                                <td colSpan="5">No languages listed</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </TabPanel>
                    </div>
                </div>
            </div>
        </section>
    </>
    
  );
}

export default Profile;
