import React, { useRef, useState, useEffect,useContext } from 'react';
import { Suspense, lazy } from 'react';
import { Link } from "react-router-dom";
import JobFilter from './jobFilter';
import { RiSearchLine, RiFilter3Line } from "react-icons/ri";
import { IoCallOutline } from "react-icons/io5";
import { FiFilter } from "react-icons/fi";
import api, { baseURL } from '../axiosInterceptor/axiosInterceptorC';
import companyLogo7 from '../../assets/images/logo.png';
import emptyImg from '../../assets/images/emptyImg.jpg';
import axios from 'axios';
import { SearchContext } from "../searchcontext/SearchContexts";
const path = "";

const JobBox = lazy(() => import("../home/jobBox"));

function JobsWraper() {
  const [filters, setFilters] = useState({});
  const jobFilterRef = useRef(null);
  
  const filterBtnClick = () => {
    const jobWraperFilter = document.querySelector('.jobWraperFilter');
    const shimmer = document.querySelector('.shimmer');
    const body = document.querySelector('body');

    if (jobWraperFilter) {
      jobWraperFilter.style.left = '0';
    }
    if (shimmer) {
        shimmer.style.display = 'block';
    }
    if (body) {
        body.style.overflow = 'hidden';
    }
  };

  const handleFilterItemClick = (filterType) => {
    if (jobFilterRef.current) {
      jobFilterRef.current.toggleDropdown(filterType);
      filterBtnClick(); // Open the filter overlay
    }
  };


  const [jobData, setJobData] = useState([]);
  const [noDataFound, setNoDataFound] = useState(false);
  const [jobDatalen, setJobDatalen] = useState(0);
  const[newjobvalleng,setnewjobval]= useState(0);
  const [offset, setOffset] = useState(0);
  const limit = 10;
  const fetchedJobIds = useRef(new Set());
  const [allJobsLoaded, setAllJobsLoaded] = useState(false);
  const [totalJobs, setTotalJobs] = useState(0);
  const [loading, setLoading] = useState(true);
  const [keywordItem, setKeywordItem] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const { searchdata, setsearchDatas } = useContext(SearchContext);
//console.log(noDataFound)
  //console.log(searchdata.Searchjob);
  useEffect(() => {
    fetchJobs(0);
    if (searchdata.Searchjob) {
      setJobData([]);  // Clear existing job data
      setnewjobval(0);
      setOffset(0);    // Reset offset for new search
      fetchedJobIds.current.clear();  // Clear previously fetched jobs
    }
  }, [searchdata.Searchjob]);
  
  
  // Fetch jobs
  const fetchJobs = async (newOffset, newFilters = filters) => {
    const login_id = localStorage.getItem('login_id');
  
    try {
      // If newOffset is 0, it means we are either fetching for the first time or applying new filters, so reset the job data
      if (newOffset === 0) {
        setJobData([]); // Clear job data
        fetchedJobIds.current.clear(); // Clear the fetched job IDs cache
        setNoDataFound(false);
      }
  
      // Fetch jobs from the backend
      const response = await axios.get(`${baseURL}/fetch_job_details_all`, {
        params: {
          offset: newOffset,
          login_id,
          filters: newFilters,
          Searchdata: searchdata.Searchjob || null,
          limit
        }
      });
    console.log(response.data.jobs)
      // Filter out jobs that have already been fetched to avoid duplicates
      const newJobs = response.data.jobs.filter(job => !fetchedJobIds.current.has(job.id));
      setnewjobval(newJobs.length); // Update the count of new jobs loaded
  
      // Add the new jobs to the fetched job IDs cache
      newJobs.forEach(job => fetchedJobIds.current.add(job.id));
      console.log(response.data.total)
      // Update jobData state by appending newJobs to the previous data
      setJobData(prevData => {
        const updatedJobData = [...prevData, ...newJobs]; // Combine previous jobs and new jobs
        console.log(updatedJobData.length)
        if (updatedJobData.length === 0) {
          setNoDataFound(true); // Set noDataFound to true if no jobs are found
        }
        const totalLoadedJobs = updatedJobData.length; // Calculate the total jobs loaded so far
  
        console.log(`Total Loaded Jobs: ${totalLoadedJobs}`); // Correct logging of total jobs loaded
        
        // Check if all jobs are loaded based on the total jobs from the backend
        if (totalLoadedJobs >= response.data.total) {
          setAllJobsLoaded(true); // Mark all jobs as loaded
        }
      else{
        setAllJobsLoaded(false);
      }
        return updatedJobData; // Return the updated job data to update the state
      });
  
      setTotalJobs(response.data.total); // Set the total number of jobs returned from the backend
      setLoading(false); // Stop loading spinner
  
    } catch (error) {
      console.error('Error fetching job data:', error);
  
      // Handle error if no data is returned (e.g., 404 error)
      if (error.response && error.response.status === 404) {
        setAllJobsLoaded(true); // Set all jobs as loaded if no more data is available
        setNoDataFound(true);
      }
  
      setLoading(false); // Stop loading spinner in case of error
    }
  };
  


  const handleApplyFilters = (newFilters) => {
    setFilters(newFilters);
    setJobData([]);  // Clear the job data
    setOffset(0);    // Reset offset
    fetchedJobIds.current.clear();  // Clear fetched job IDs
   // setAllJobsLoaded(false);  // Reset allJobsLoaded to false for fresh fetch
    fetchJobs(0, newFilters); // Fetch new jobs with applied filters
  };
  

  const handleShowMore = () => {
      const newOffset = offset + limit;
      setOffset(newOffset);
      fetchJobs(newOffset);
      
  };

  return (
    <>
      <section id="jobsWraper">
        <div className="jobFilterBtnStrip">
          <div className="container">
            <div className="jobFilterBtnStripMain">
              <div className="filterBtn" onClick={filterBtnClick}><FiFilter /></div>
              <ul>
                <li onClick={() => handleFilterItemClick('keyword')}>Keyword</li>
                {/* <li onClick={() => handleFilterItemClick('position')}>Position Name</li> */}
                <li onClick={() => handleFilterItemClick('jobTitle')}>Job Title</li>
                <li onClick={() => handleFilterItemClick('location')}>Location</li>
                <li onClick={() => handleFilterItemClick('workEnvironment')}>Work Environment</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="jobsWraperMain">
            {/* Pass handleApplyFilters to JobFilter */}
            <JobFilter ref={jobFilterRef} applyFilters={handleApplyFilters} />
            <div className="jobWraperList">
            {loading ? (
                <Suspense>
                  <JobBox jobLink='' jobCompanyImg='' jobCompanyName='' jobTitle='' jobLocation='' jobExperiance='' jobSalary='' jobOpening='' jobPostedDate='' applyJobLink='' Shortdescription='' />
                </Suspense>
              ) : noDataFound ? ( // Check for noDataFound state
                <div className="noDataFound">
                  <div className="noDataFoundIcon">
                    <img src={emptyImg} alt="" />
                  </div>
                  <div className="noDataFoundDetails">
                    <h3>Oops!</h3>
                    <p>If you don’t find what you are looking for, call/WhatsApp us on 0091 94478 70999 and we will be happy to personally find a good fit for you.</p>
                    <Link to="tel:00919447870999" className="noDataFoundClearFIlterBtn">
                      <IoCallOutline /> Call Now
                    </Link>
                  </div>
                </div>
              ) : (
                jobData.map((job, index) => (
                  <Suspense key={index}>
                    <JobBox
                      jobLink={`${path}/jobDetails/${job.id}`}
                      jobCompanyImg={companyLogo7}
                      jobCompanyName={job.company_name}
                      jobTitle={job.job_title}
                      jobLocation={job.location_val}
                      jobExperiance={job.experience_range}
                      jobSalary={`${job.from_amount} - ${job.to_amount}${job.salary_currency}`}
                      jobOpening={job.no_openings}
                      jobPostedDate={job.days_ago}
                      applyJobLink={`${path}/applyJobForm/${job.id}`}
                      Shortdescription={job.short_description}
                      jobidval={job.id}
                      savedstatus={job.savedstatus}
                      applystatus={job.apply_status}
                    />
                  </Suspense>
                ))
              )}
              {!allJobsLoaded && jobData.length > 0 && (
                <div className="jobWraperLoadMore">
                  <div className="jobWraperLoadMoreBtn" onClick={handleShowMore}>Show More</div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default JobsWraper;