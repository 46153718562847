import React, { useState, useEffect, lazy } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { GoChevronRight, GoArrowRight, GoArrowLeft } from "react-icons/go";
import axios from 'axios';
import AvatarImg from '../../assets/images/avatar.png';
import { baseURL,mediaUrl } from '../axiosInterceptor/axiosInterceptorC'; 
import emptyImg from '../../assets/images/emptyImg.jpg'
import emptyImg2 from '../../assets/images/emtyImg2.jpg'
import { IoCallOutline } from "react-icons/io5";
const HomeTestimonialsBox = lazy(() => import("./homeTestimonialsBox"));

const path = "";

function HomeTestimonials() {
    const settings = {
        dots: false,
        infinite: false, // Make sure infinite is false if only one testimonial is available
        autoplay: false, // Disable autoplay to avoid confusion
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,  // Only scroll one slide at a time
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    const [testimonials, setTestimonials] = useState([]);

    // Fetch testimonials only once when component mounts
    useEffect(() => {
        const fetchTestimonials = async () => {
            try {
                const response = await axios.get(`${baseURL}/testimonials`);
                
                if (response.data && response.data.data) {
                    setTestimonials(response.data.data);
                    console.log("Fetched testimonials:", response.data.data); // Log fetched data
                    console.log("Number of testimonials:", response.data.data.length); // Log the length
                } else {
                    setTestimonials([]);
                }
            } catch (error) {
                console.error('Error fetching testimonials:', error);
                setTestimonials([]);
            }
        };

        fetchTestimonials();
    }, []); // Empty dependency array ensures this runs only once when the component mounts

    return (
        <>
        {testimonials.length > 0 && (
        <section id='homeTestimonials'>
            <div className="container">
                <div className="homeTestimonialsHead">
                    <h2 className="title">Testimonials</h2>
                    <h3 className="mainTitle">Real Stories of Success and Satisfaction</h3>
                </div>
                <div className="homeTestimonialsMain">
                    <div className="homeTestimonialsCol2">
                        {
                            testimonials.length > 0 ?(
                                <Slider {...settings}>
                                
                                    {testimonials.map((testimonial, index) => (
                                        <div className="item" key={index}>
                                            <HomeTestimonialsBox 
                                                homeTestimonialsContent={testimonial.testiomnial_desc}
                                                homeTestimonialsIframe={testimonial.iframe_val}
                                                homeTestimonialsImg={AvatarImg} 
                                                homeTestimonialsName={testimonial.person_name}
                                                homeTestimonialsDesignation={testimonial.designation} 
                                            />
                                        </div>
                                    ))}
                                </Slider>
                            ):(
                                <div className="noDataFound">
                                    <div className="noDataFoundIcon">
                                    <img src={emptyImg2} alt="" />
                                    </div>
                                    <div className="noDataFoundDetails">
                                    <h3>Opps!</h3>
                                    <p>Testimonials Not Available</p>
                                    </div>
                                </div>
                            )

                        }               
                    </div>
                </div>
                {testimonials.length > 0 && (
                    <div className="homeTestimonialsFooter">
                        <Link to={`${path}/testimonials`}>View More <GoChevronRight /></Link>
                    </div>
                    )
                }
            </div>
        </section>
        )}
</>
    );
}

function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <button
            className={className}
            style={{ ...style, display: "block" }}
            onClick={onClick}
        >
            <GoArrowRight />
        </button>
    );
}

function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <button
            className={className}
            style={{ ...style, display: "block" }}
            onClick={onClick}
        >
            <GoArrowLeft />
        </button>
    );
}

export default HomeTestimonials;
