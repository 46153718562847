import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { FiSend } from "react-icons/fi";
import { BiLogoLinkedin, BiLogoFacebook, BiLogoInstagramAlt, BiLogoYoutube } from "react-icons/bi";
import { IoCallOutline, IoLocationOutline, IoMailOutline } from "react-icons/io5";
import api, { baseURL } from '../axiosInterceptor/axiosInterceptorC';
import { toast } from 'react-toastify';
import Loader from '../loader/buttonLoader';
function Contact() {
  // State to hold form values
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    message: ""
  });

  // State to track errors
  const [errors, setErrors] = useState({});
  const [responseMessage, setResponseMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const inputRef = useRef(null);

  // Handle input change and remove error for the specific field
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    
    // Remove the error for this specific field as the user types
    setFormData({ ...formData, [name]: value });
    if (errors[name]) {
      setErrors({ ...errors, [name]: null });
    }
  };

  // Validate form inputs
  const validate = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = "Name is required.";
    if (!formData.phone) newErrors.phone = "Phone is required.";
    if (!formData.email) newErrors.email = "Email is required.";
    if (!formData.message) newErrors.message = "Message is required.";
    return newErrors;
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true); // Disable the button
    setIsLoading(true)
    // Validate the form
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors); // Set validation errors
      setIsSubmitting(false); // Re-enable the button
      setIsLoading(false)
      return; // Stop form submission
    }

    try {
      // Make an Axios POST request to your Laravel API endpoint
      const response = await axios.post(`${baseURL}/contact`, formData);
      console.log(response.data)
      // Clear form on success
      setFormData({
        name: "",
        phone: "",
        email: "",
        message: ""
      });

      setErrors({}); // Clear any errors
      // setResponseMessage("Message sent successfully!");
      toast.success("Message sent successfully!");
      setIsLoading(false)
    } catch (error) {
      // setResponseMessage("Failed to send message. Please try again.");
      toast.error("Failed to send message. Please try again.");
      setIsLoading(false)
    } finally {
      setIsSubmitting(false); // Re-enable the button
      setIsLoading(false)
    }
  };

  const handleKeyDown = (evt) => {
    // Prevent the input of digits
    if (/^\d$/.test(evt.key)) {
      evt.preventDefault();
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
        event.preventDefault();
      }
    };

    const inputElement = inputRef.current;
    if (inputElement) {
      inputElement.addEventListener('keydown', handleKeyDown);
    }

    // Cleanup event listener on unmount
    return () => {
      if (inputElement) {
        inputElement.removeEventListener('keydown', handleKeyDown);
      }
    };
  }, []);
  

  return (
    <>
      <section id="contact">
        <div className="container">
          <div className="contactTitle">
            <h2 className="title">Contact Us</h2>
            <h1 className="mainTitle">Get in Touch, We're Here to Help</h1>
          </div>
          <div className="contactMain">
            <div className="contactCol1">
              <h2 className="subTitle">Message Us</h2>
              <form onSubmit={handleSubmit}>
                <div className="formGroup">
                  <label htmlFor="name">Name</label>
                  <input
                    onKeyDown={handleKeyDown}
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                  />
                  {errors.name && <p className="error">{errors.name}</p>} {/* Show error */}
                </div>
                <div className="formGroup">
                  <label htmlFor="phone">Phone</label>
                  <input
                    type="number"
                    name="phone"
                    value={formData.phone}
                    onChange={handleInputChange}
                    ref={inputRef}
                  />
                  {errors.phone && <p className="error">{errors.phone}</p>} {/* Show error */}
                </div>
                <div className="formGroup">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                  />
                  {errors.email && <p className="error">{errors.email}</p>} {/* Show error */}
                </div>
                <div className="formGroup">
                  <label htmlFor="message">Message</label>
                  <textarea
                    name="message"
                    value={formData.message}
                    onChange={handleInputChange}
                  ></textarea>
                  {errors.message && <p className="error">{errors.message}</p>} {/* Show error */}
                </div>
                <div className="formBtnArea">
                  <button type="submit" disabled={isSubmitting}>
                  {isLoading ? <Loader /> : 'Send'}<FiSend />
                  </button>
                </div>
              </form>
              {responseMessage && <p>{responseMessage}</p>}
            </div>
            <div className="contactCol2">
              {/* Social links and contact details */}
              <div className="contactDetailsBox">
                <h2 className="subTitle">Chat With Us</h2>
                <div className="contactSocialLinks">
                  <a href="https://www.linkedin.com/company/daniels-job-finder" target="_blank"><BiLogoLinkedin /></a>
                  <a href="https://www.facebook.com/danielsjobfinder" target="_blank"><BiLogoFacebook /></a>
                  <a href="https://www.instagram.com/daniels_job_finder?igsh=MWhxcm52Zjl3MmV6dw==" target="_blank"><BiLogoInstagramAlt /></a>
                  {/* <a href="#" target="_blank"><BiLogoYoutube /></a> */}
                </div>
              </div>
              <div className="contactDetailsBox">
                <h2 className="subTitle">Visit Us</h2>
                <div className="contactDetailsBoxLinks">
                  <a href="#" target="_blank"><IoLocationOutline /> Daniels International Recruitment Pvt Ltd. <br />Ayanikkattu zone,Malikam Peedika,Alangad PO,PIN:683511, <br />Kerala state - India</a>
                </div>
              </div>
              <div className="contactDetailsBox">
                <h2 className="subTitle">Call Us</h2>
                <div className="contactDetailsBoxLinks">
                  <a href="tel:00919447870999" target="_blank"><IoCallOutline /> 0091 94478 70999</a>
                  <a href="tel:00971562979900" target="_blank"><IoCallOutline /> 0097 15629 79900</a>
                </div>
              </div>
              <div className="contactDetailsBox">
                <h2 className="subTitle">For Malayalam</h2>
                <div className="contactDetailsBoxLinks">
                  <a href="tel:00919207055999" target="_blank"><IoCallOutline /> 0091 920705 5999</a>
                </div>
              </div>
              <div className="contactDetailsBox">
                <h2 className="subTitle">Mail Us</h2>
                <div className="contactDetailsBoxLinks">
                  <a href="mailto:info@danielsjobfinder.com" target="_blank"><IoMailOutline /> info@danielsjobfinder.com</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Contact;
