// SearchContext.js
import { createContext, useState } from "react";

export const SearchContext = createContext();

export const SearchProvider = ({ children }) => {
  const [searchdata, setsearchDatas] = useState({
    Searchdata: "",
  });
  return (
    <SearchContext.Provider value={{ searchdata, setsearchDatas }}>
      {children}
    </SearchContext.Provider>
  );
};
