import axios from 'axios';
import { useNavigate } from 'react-router-dom';
//export const baseURL='http://127.0.0.1:8000/api';
//export const mediaUrl = 'http://127.0.0.1:8000/';
export const mediaUrl = 'https://danielsjobfinder.com/demo3/public/';
export const baseURL='https://danielsjobfinder.com/demo3/api';
const axiosInstance = axios.create({
    baseURL: baseURL,
});

axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response && error.response.status === 401) {
            // Session expired or invalid session
            useNavigate('login');
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;
