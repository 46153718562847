
import React, { useContext, useState,useEffect } from 'react';
import loginImg from '../../assets/images/login.jpg'
import { ImGoogle } from "react-icons/im";
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import { baseURL } from '../axiosInterceptor/axiosInterceptorC';
import Loader from '../loader/buttonLoader';
import { LoginPopupContext } from '../loginpopup/LoginPopupContext';
const path = "";
function Register() {

    const navigate = useNavigate();

    // const handleGetOTP = (e) => {
    //     e.preventDefault();
    //     toast.info('OTP has been sent to your phone');
    //     navigate(`${path}/otp`);
    // };
    
    const [isLoginPopupVisible, setIsLoginPopupVisible] = useContext(LoginPopupContext);
    useEffect(() => {
        const shimmer = document.querySelector('.shimmer');
        shimmer.style.display = 'none'; 
        setIsLoginPopupVisible(false);  
    })
    
    const[first_name,setfirstname]=useState('');
    const[last_name,setlastname]=useState('');
    const[emailval,setemailval]=useState('');
    const[phonenumber,setphonenumber]=useState('');
    const [errors, setErrors] = useState({ first_name: '', emailval: '', phonenumber: '' });
    const [isDisable, setIsDisable] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        console.log("Submitting form with values:", { first_name, emailval, phonenumber });
        // Reset validation errors and alerts
        setErrors({ first_name: '', emailval: '', phonenumber: '' });
        setSuccess(false);
        setError(false);
    
        // Validate inputs
        let valid = true;
        if (!first_name.trim()) {
            setErrors(prev => ({ ...prev, first_name: 'First Name is required' }));
            valid = false;
        }
        if (!last_name.trim()) {
            setErrors(prev => ({ ...prev, last_name: 'Last Name is required' }));
            valid = false;
        }
        if (!emailval.trim()|| !/\S+@\S+\.\S+/.test(emailval)) {
            setErrors(prev => ({ ...prev, emailval: 'Valid Email id is required' }));
            valid = false;
        }
        if (phonenumber.length < 6) {
            setErrors(prev => ({ ...prev, phonenumber: 'Valid Phone number is Required' }));  // Fix this line
            valid = false;
        }
        if (valid) {
            setIsLoading(true)
            setIsDisable(true);
            const formData = new FormData();
            formData.append('first_name', first_name);
            formData.append('last_name', last_name);
            formData.append('emailval', emailval);
            formData.append('phonenumber', phonenumber);
            
            try {
                const response = await axios.post(`${baseURL}/addregister`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
    
                // Handle success response
                console.log('Success:', response.data);
    
                if (response.data.status === 1) {
                    setSuccess(true);
                    setIsLoading(false)
                    localStorage.setItem('otp_code', response.data.otp_code);
                    localStorage.setItem('emailval', response.data.emailval);
                    localStorage.setItem('login_id', response.data.login_id);
                    localStorage.setItem('candidate_name', response.data.candidate_name);
                    

                    setTimeout(() => {
                        localStorage.removeItem('otp_code');
                    }, 60000);

                    setfirstname('');
                    setlastname('');
                    setemailval('');
                    setphonenumber('');
                    setIsDisable(false); // Enable the button again
                    setTimeout(() => {
                        navigate(`${path}/otp`);
                        //window.location.href = `${path}/`;
                    }, 100);
                } else {
                    setError(true);
                    setIsLoading(false)
                }
    
                // Reset form fields after successful submission
                
            } catch (err) {
                console.log(err)
                setIsLoading(false)
                setError(true);
                setIsDisable(false); // Enable the button again
                if(err.status==422){
                    setErrors(prev => ({ ...prev, emailval: 'Email id already exist' }));
                }
            }
        } else {
            setError(false);
            setIsDisable(false); // Enable the button again
        }
    };
     
  return (
    <>
        <section id="loginSection">
            <div className="container">
                <div className="loginSectionMain">
                    <div className="loginSectionThumbnail">
                        <img src={loginImg} alt="" />
                        <div className="loginSectionThumbnailDetails">
                            <h2>Embrace new opportunities with us and start a journey that could reshape your life.</h2>
                        </div>
                    </div>
                    <div className="loginSectionDetails">
                        <h2 className='title'>Create An Account</h2>
                        <p>Enter your details below and take the first step toward new possibilities.</p>
                        <form onSubmit={handleSubmit} encType="multipart/form-data">
                            <div className="formGroup">
                                <label htmlFor="">Enter Your First Name</label>
                                <input type="text" 
                                name="first_name"
                                value={first_name}
                                onChange={(e) => setfirstname(e.target.value)}/>
                                {errors.first_name && <p className="error">{errors.first_name}</p>}
                            </div>
                            <div className="formGroup">
                                <label htmlFor="">Enter Your Last Name</label>
                                <input type="text" 
                                name="last_name"
                                value={last_name}
                                onChange={(e) => setlastname(e.target.value)}/>
                                {errors.last_name && <p className="error">{errors.last_name}</p>}
                            </div>
                            <div className="formGroup">
                                <label htmlFor="">Enter Your Email ID</label>
                                <input type="email" 
                                name="emailval"
                                value={emailval}
                                onChange={(e)=>setemailval(e.target.value)}/>
                                {errors.emailval && <p className="error">{errors.emailval}</p>}
                            </div>
                            <div className="formGroup">
                                <label htmlFor="">Enter Your Phone Number</label>
                                <input type="number" 
                                name="phonenumber"
                                value={phonenumber}
                                onChange={(e)=>setphonenumber(e.target.value)}/>
                                {/* <div className="error">This phone number not registered</div> */}
                                {errors.phonenumber && <p className="error">{errors.phonenumber}</p>}
                            </div>
                            <div className="formBtnArea">
                                <button type="submit" disabled={isDisable}>{isLoading ? <Loader /> : 'Get OTP'}</button>
                            </div>
                            {/* <div className="orText">Or</div>
                            <Link className="signingGoogle"><ImGoogle /> Sign in with google</Link> */}
                        </form>
                        <div className="logingFooterStrip">
                            <p>I have an account.</p>
                            <Link to={`${path}/login`}>Login Now</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  );
}

export default Register;
